import Vue from 'vue'
import Vuex from 'vuex'

import local from './modules/local'
import usuario from './modules/usuario'
import profissao from './modules/profissao'
import cidade from './modules/cidade'
import credenciados from './modules/credenciados'
import credenciado from './modules/credenciado'
import operadora from './modules/operadora'
import produto from './modules/produto'
import plano from './modules/plano'
import regiao from './modules/regiao'
import rede from './modules/rede'
import reembolso from './modules/reembolso'
import carencia from './modules/carencia'
import procedimento from './modules/procedimento'
import docs from './modules/docs'
import info from './modules/info'
import arquivo from './modules/arquivo'
import share from './modules/share'
import modalFilter from './modules/modalFilter'
import b2cor from './modules/b2cor'
import notificar from './modules/notificar'
import email from './modules/email'
import administradora from './modules/administradora'
import entidade from './modules/entidade'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    local,
    usuario,
    profissao,
    cidade,
    credenciados,
    credenciado,
    operadora,
    produto,
    plano,
    regiao,
    rede,
    reembolso,
    carencia,
    procedimento,
    docs,
    info,
    arquivo,
    share,
    modalFilter,
    b2cor,
    notificar,
    email,
    administradora,
    entidade
  }
})
