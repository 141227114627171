import axios from 'axios'

class B2CorService {

  constructor () {
    this.$http = axios.create({
      baseURL: process.env.VUE_APP_B2COR_API_URL
    })

    this.$http.interceptors.request.use(config => {
      const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
      const json = JSON.parse(id)
      const user = json?.user
      if (user) {
        config.headers['x-api-key'] = `${user.token}`
      }
      return config
    }, error => Promise.reject(error))
  }

  buscaLeads(user, search) {
    return this.$http.post('lead/find', {
      user: user,
      search: search,
      order_by: 'id',
      order_sort: 'desc'
    })
    .then(response => {
      return response.data
    });
  }

  pegarLead(user, id) {
    return this.$http.get('lead/get/'+id)
    .then(response => {
      return response.data
    });
  }

  criarIndicacao(nome, email, fone, assunto, cotacao) {
    return this.$http.post('lead/add/simp', {
      nome: nome,
      email: email,
      fone: fone,
      assunto: assunto,
      cotacao: cotacao
    })
    .then(response => {
      return response.data
    });
  }

  atualizarIndicacao(indicacao, nome, email, fone, assunto, cotacao) {
    return this.$http.post('lead/edit/simp', {
      indicacao: indicacao,
      nome: nome,
      email: email,
      fone: fone,
      assunto: assunto,
      cotacao: cotacao
    })
    .then(response => {
      return response.data
    });
  }

  enviarMensagem(id, contato_tipo, contato_data, contato_horario, mensagem, informacao) {
    return this.$http.post(`lead/contato/${id}`, {
      "contato_tipo": contato_tipo,
      "contato_data": contato_data,
      "contato_horario": contato_horario,
      "mensagem": mensagem,
      "informacao": informacao
     })
    .then(response => {
      return response.data
    });
  }
}

export default new B2CorService();
