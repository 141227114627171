import Vue from 'vue'

class ShareService {
  enviarCotacao(cotacao) {
    return Vue.prototype.$http.post(
      `cotacao/salvar/tabela/${cotacao.id}`,
      {
        "url": cotacao.url,
        "titulo": cotacao.assunto,
        "nome": cotacao.nomeCliente,
        "email": cotacao.emailCliente,
        "fone": cotacao.whatsAppCliente,
        "detalhes": cotacao.detalhes,
        "parametros": cotacao.parametros,
        "opcoes": cotacao.opcoes,
        "arqSelected": cotacao.arqSelected,
        "indicacao": cotacao.indicacao,
        "alterar_msg": cotacao.checkMsgPadrao
    })
    .then(response => {
      return response.data;
    });
  }
  atualizarCotacao(cotacao) {
    return Vue.prototype.$http.post(`cotacao/atualizar/${cotacao.id}`, {
      "validade": cotacao.validade
   })
    .then(response => {
      return response.data;
    });
  }
  cotacao(id) {
    return Vue.prototype.$http.get(`cotacao/buscar/${id}`)
    .then(response => {
      return response.data
    });
  }
  email(cotacao) {
    return Vue.prototype.$http.post(`/email/enviar/${cotacao.id}`, {
      cc: cotacao.cc,
      comentario: cotacao.comentario,
      copia_corretor: cotacao.copia_corretor
    })
    .then(response => {
      return response.data
    })
  }
  contato(
    codigo,
    nome,
    email,
    celular,
    telefone,
    contato_tipo,
    contato_data,
    contato_horario,
    mensagem,
    informacao
  ) {
    return Vue.prototype.$http.post(`cliente/contato/${codigo}`, {
      "nome": nome,
      "email": email,
      "celular": celular,
      "telefone": telefone,
      "contato_tipo": contato_tipo,
      "contato_data": contato_data,
      "contato_horario": contato_horario,
      "mensagem": mensagem,
      "informacao": informacao
     })
    .then(response => {
      return response.data
    });
  }
}

export default new ShareService();
