import ReembolsoService from '@/services/reembolso.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    reembolso: null,
    reembolsos: [],
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setReembolsos(state, reembolsos) {
      state.reembolsos = reembolsos
    },
    setReembolso(state, reembolso) {
      state.reembolso = reembolso
    }
  },
  actions: {
    buscaReembolso(
      { commit },
      {
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        planos,
        administradora,
        entidade
      }
    ) {
      commit('setLoading', true)
      return ReembolsoService.busca(
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        planos,
        administradora,
        entidade
      ).then(resp => {
        commit('setLoading', false)
        commit('setReembolsos', resp)
        return Promise.resolve(resp);
      }, error => {
        commit('setLoading', false)
        return Promise.reject(error);
      })
    }
  },
  getters: {
    getLoading(state) {
      return state.loading
    },
    getReembolso(state) {
      return state.reembolso
    },
    getReembolsos(state) {
      return state.reembolsos
    }
  }
}
