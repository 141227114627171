<template>
  <div ref="body" role="tablist" class="valores" :style="[baseStyles]">
    <button class="scroll-button float-left" @click="sideScroll('left')" v-if="showLeftArrow">
        <b-icon icon="caret-left-fill"></b-icon>
    </button>
    <button icon="caret-right-fill" class="scroll-button float-right" @click="sideScroll('right')" v-if="showRightArrow">
        <b-icon icon="caret-right-fill"></b-icon>
    </button>
    <b-table
      ref="bodyOverflow"
      class="table table-sm host table-striped b-table b-table-no-border-collapse"
      :class="'overflowing-' + overflow"
      :fields="normalizeFields(fields)"
      :items="items"
      >
      <template #head(plano)>
          <div class="text-nowrap"></div>
      </template>
      <template #head(idade)>
          <div class="text-nowrap">Idade</div>
      </template>
      <template #head()="scope">
          <div>
          {{ scope.label }}
          </div>
      </template>
      <template #cell(idade)="scope">
          <div class="text-nowrap">
          {{ scope.value | idade }}
          </div>
      </template>
      <template #cell(plano)="scope">
      <div>
          {{ scope.value }}
      </div>
      </template>
      <template #cell()="scope">
          <div class="text-nowrap">
          {{ scope.value | dinheiro}}
          </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import FlexTableHeaderScroll from '@/components/FlexTableHeaderScroll'

export default {
  props: {
    fields: {
      type: Array,
      default: function() { return [] }
    },
    items: {
      type: Array,
      default: function() { return [] }
    }
  },
  mixins: [FlexTableHeaderScroll],
  computed: {
    width() {
      let tipo = this.fields.find(f => f.key == 'idade'),
        width = 60
      if (tipo) {
        width = this.$isMobile ? 50 : 60
      } else {
        width = this.$isMobile ? 140 : 150
      }
      return width
    },
    totalItems() {
      return this.fields.length
    },
    baseStyles() {
      return {
        '--width': (this.width) + 'px',
        '--headerHeight': this.$refs.headerRede ? this.$refs.headerRede.clientHeight + 'px' : '0',
        '--minWidth': (100 + this.totalItems * 160) + 'px'
      }
    },
  },
  methods: {
    normalizeFields(fields) {
      return fields.map(f => {
        if (f.hasOwnProperty('isRowHeader')) {
          return {
            isRowHeader: parseInt(f.isRowHeader) ? true : false,
            key: f.key,
            stickyColumn: parseInt(f.stickyColumn) ? true : false,
          }
        }
        return f
      })
    },
  }
}
</script>

<style scope lang="scss">
.valores {
  width: 100%;
  height: 100% !important;
  min-height: 100% !important;
  overflow-x: auto;
  overflow-y: visible;
  & table  {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    min-width: var(--minWidth);
    margin-bottom: 0 !important;
    & th {
      font-weight: normal;
    }
  }
  & .eixo {
    z-index: 3 !important;
  }
  & th.eixo {
    width: var(--freezeWidth);
  }
  & thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    /* z-index: 2; */
  }
  & table td:first-child,
  & table th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
    background: #fff;
  }
  & table th:first-child {
    width: var(--width);
    padding-left: 24px;
  }
  & thead tr:nth-child(2) th {
    background-color: #f2f2f2;
    -webkit-print-color-adjust: exact;
    position: -webkit-sticky;
    position: sticky;
    top: 75px;
    left: 0;
    z-index: 2;
    height: 40px;
  }
  & .table-striped tbody > tr:nth-of-type(2n) {
    background-color: #fff;
  }
  & table tr.alternate th,
  & table tr.alternate td {
    background-color: #f2f2f2;
    -webkit-print-color-adjust: exact;
  }
  & table {
    &.overflowing-both,
    &.overflowing-left {
      tbody th:first-child {
        box-shadow: -2px 20px 20px rgba(135, 135, 135, 0.5);
      }
    }
  }
}
</style>
