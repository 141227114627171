<template>
  <overlay :show="!loadingAll">
    <b-container class="impressao">
      <b-row>
        <b-col cols="10">
          <div class="card-container logo-corretora">
            <div class="card card-logo">
              <div class="content">
                <img :src="user.logotipo"
                  :alt="user.empresa"
                  style="max-height: 100%;" />
              </div>
            </div>
          </div>
          <div class="info-plano-selecionado">
            <h4>{{ produto.nome }} - {{ operadora.cidade.cidade}}</h4>
            <h6>{{ subtitle }}</h6>
            <p>Compulsório: {{ textCom }}</p>
            <p>Coparticipação: {{ textCop }}</p>
            <p>Acomodação: {{ textAcom }}</p>
            <p>Aceita MEI: {{ textMei }}</p>
            <p>Corretor: {{ user.name }} - {{ user.login }}</p>
          </div>
        </b-col>
        <b-col cols="2">
          <div class="card-container logo-operadora float-right">
            <div class="card card-logo">
              <div class="content">
                <div class="logo-img" style="width: 108px;" >
                  <img :src="operadoraLogoTipo" width="108px" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="showValores">
        <b-col cols="12 border mt-1">
          <h4>Valores</h4>
          <b-row v-for="grupo in filterValores" :key="grupo.id">
            <b-col cols="12" role="tablist" class="grupo">
              <div class="fz-14 mb-1" style="font-weight: 600;line-height: 1.75;text-transform: uppercase;">{{ grupo.nome }}</div>
              <div class="mr-1" style="font-size: 12px; font-weight: 600;color: #9E9E9E;font-weight: 400;text-transform: uppercase;">
                <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
                <span class="mr-3">{{ grupo.min_vidas }} a {{ grupo.max_vidas }}</span>

                <font-awesome-icon :icon="['fas', 'calendar-check']" class="mr-1" v-if="grupo.data_inicio" />
                <span class="mr-3" v-if="grupo.data_inicio">A partir {{ grupo.data_inicio | data }}</span>
                <font-awesome-icon :icon="['fas', 'hourglass-end']" class="mr-1" v-if="grupo.data_fim" />
                <span class="mr-3" v-if="grupo.data_fim"> Até {{ grupo.data_fim | data }}</span>

                <font-awesome-icon :icon="['fas', 'hand-holding-usd']" class="mr-1" v-if="grupo.coparticipacao" />
                <span class="mr-3" v-if="grupo.coparticipacao">Coparticipação</span>
                <span class="mr-3" v-if="grupo.coparticipacao_porcentagem">{{ grupo.coparticipacao_porcentagem | porcentagem }}</span>
                <font-awesome-icon :icon="['fas', 'globe']" class="mr-1" v-if="grupo.compulsorio" />
                <span v-if="grupo.compulsorio">Compulsório</span>

                <font-awesome-icon :icon="['fas', 'user-tie']" class="mr-1" v-if="grupo.mei" />
                <span v-if="grupo.mei">Aceita MEI</span>
              </div>
              <b-table
                no-border-collapse
                responsive
                bordered
                striped
                small
                :fields="normalizeFields(grupo.fields)"
                :items="grupo.items"
              >
                <template #head(plano)>
                    <div class="text-nowrap"></div>
                </template>
                <template #head(idade)>
                    <div class="text-nowrap">Idade</div>
                </template>
                <template #head()="scope">
                    <div>
                    {{ scope.label }}
                    </div>
                </template>
                <template #cell(idade)="scope">
                    <div class="text-nowrap">
                    {{ scope.value | idade }}
                    </div>
                </template>
                <template #cell(plano)="scope">
                <div>
                    {{ scope.value }}
                </div>
                </template>
                <template #cell()="scope">
                    <div class="text-nowrap">
                    {{ scope.value | dinheiro}}
                    </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="showRedes" class="page">
        <b-col cols="12 border mt-1">
          <h4>Redes</h4>
          <div class="text-center">
            <loader
              :width="'150px'"
              :height="'150px'"
              v-if="loadingRedes" />
          </div>
          <div class="redes" role="tablist" v-if="regioes.length > 0">
            <b-row v-for="(regiao, rindex) in filterRegioes" :key="rindex">
              <b-col cols="12">
                <h5>{{ regiao.nome }}</h5>
                <div v-for="(cidade, cindex) in regiao.cidades" :key="cindex">
                  <div class="mb-1">{{ cidade.nome }}</div>
                  <table class="table b-table table-striped table-bordered table-sm b-table-no-border-collapse">
                    <template v-for="(tipo, i) in Object.keys(cidade.credenciados)">
                      <thead :key="`thead-${i}`">
                        <tr>
                          <th class="eixo" style="width: 180px;">
                            {{ tipo }}
                          </th>
                          <th style="width: 180px;" v-for="(plano, index) in cabCredPlanos(cidade.credenciados)" :key="index">
                            {{ i == 0 ? plano.nome : '' }}
                          </th>
                        </tr>
                      </thead>
                      <tbody :key="`tbody-${i}`">
                        <tr v-for="(credenciado, crindex) in cidade.credenciados[tipo]" :key="crindex">
                          <td class="eixo" style="min-width: 180px;">
                            <div :inner-html.prop="credenciado.nome" />
                          </td>
                          <td style="min-width: 180px;" v-for="(plano, index) in cabCredPlanos(cidade.credenciados)" :key="index">
                            <span :inner-html.prop="cobCredPlanos(plano, credenciado)" />
                            <span
                                :inner-html.prop="
                                  cobObsPlanos(plano, credenciado)
                                "
                              />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
          <p v-else-if="!loadingRedes" class="text-center h5 mt-4">
            <i class="ti-info-alt h4"></i> <br>
            Nenhuma rede encontrada!
          </p>
        </b-col>
      </b-row>
      <b-row v-if="showRedes" class="page">
        <b-col cols="12 border mt-1">
          <h4>Obs. Rede</h4>
          <ul :inner-html.prop="cobObsPlanosPrintAll()"  />          
        </b-col>
      </b-row>

      <b-row v-if="showReembolsos && reembolsos.length" class="page">
        <b-col cols="12" class="reembolsos border mt-1" role="tablist">
          <h4>Reembolsos</h4>
          <div class="table-responsive">
            <table class="table b-table table-striped table-bordered table-sm b-table-no-border-collapse" v-for="(ch, i) in chunk(planos, 4)" :key="i">
              <thead>
                <tr>
                  <th class="eixo" style="width: 130px;">Descrição do Procedimento</th>
                  <th style="width: 130px;" v-for="plano in ch" :key="plano.id">
                    {{ plano.nome }}
                    <small v-if="semReembolso(plano.id)" style="color: #dc3545">
                      <br/> <font-awesome-icon :icon="['fas', 'ban']"  /> Sem Reembolso
                    </small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(reembolso, index) in reembolsosProcedimentos" :key="index">
                  <td style="min-width: 130px;">{{ reembolso.nome }}</td>
                  <td style="min-width: 130px;" v-for="plano in ch" :key="plano.id">
                    {{ reembolsoValores(plano.id, reembolso.aId) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="showCarencias" class="page">
        <b-col cols="12 border mt-1">
          <h4>Carência</h4>
          <div class="text-center">
            <loader
              :width="'150px'"
              :height="'150px'"
              v-if="loadingCarencias" />
          </div>
          <div class="carencias" role="tablist" v-if="carenciasIsArray.length > 0">
            <div v-for="(carencia, cindex) in filterCarencia" :key="cindex">
              <h5 class="mb-1" :inner-html.prop="carencia.catNome" />
              <div class="mb-1 table-responsive" :inner-html.prop="carencia.itemConteudo" />
            </div>
          </div>
          <p v-else-if="!loadingCarencias" class="text-center h5 mt-4">
            <i class="ti-info-alt h4"></i> <br>
            Nenhuma carência encontrada!
          </p>
        </b-col>
      </b-row>
      <b-row v-if="showProcObrigatorios" class="page">
        <b-col cols="12 border mt-1">
          <h4>Proc. Obrigatório</h4>
          <div class="text-center">
            <loader
              :width="'150px'"
              :height="'150px'"
              v-if="loadingProcedimentos" />
            <div role="tablist" v-if="proc_obrigatorios.length">
              <b-row v-for="proc_obrigatorio in filterProcObrigatorio" :key="proc_obrigatorio.id">
               <b-col cols="12">
                  <h5>{{ proc_obrigatorio.nome }}</h5>
                  <div role="tablist" class="table-responsive">
                    <table class="table table-sm host table-striped b-table b-table-no-border-collapse" v-for="(ch, i) in chunk(planos, 4)" :key="i">
                      <thead>
                        <tr>
                          <th style="width: 200px;">Procedimento</th>
                          <th style="width: 200px;" v-for="plano in ch" :key="plano.id">
                            {{ plano.nome }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(procedimento, index) in proc_obrigatorio.procs" :key="index">
                          <td style="min-width: 200px;">{{ procedimento.nome }}</td>
                          <td style="min-width: 200px;" v-for="plano in ch" :key="plano.id">
                            {{ procedimentoObrigatorio(plano.id, procedimento) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </div>
            <p v-else-if="!loadingProcedimentos" class="text-center h5 mt-4">
              <i class="ti-info-alt h4"></i> <br>
              Nenhuma procedimento obrigatório encontrado!
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="showProcDiferenciados" class="page">
        <b-col cols="12 border mt-1">
          <h4>Proc. Diferenciado</h4>
          <div class="text-center">
            <loader
              :width="'150px'"
              :height="'150px'"
              v-if="loadingProcedimentos" />
            <div role="tablist" v-if="proc_diferenciados.length">
              <b-row v-for="proc_diferenciado in filterProcDiferenciado" :key="proc_diferenciado.id">
               <b-col cols="12">
                  <h5>{{ proc_diferenciado.nome }}</h5>
                  <div role="tablist" class="table-responsive">
                    <table class="table table-sm host table-striped b-table b-table-no-border-collapse" v-for="(ch, i) in chunk(planos, 4)" :key="i">
                      <thead>
                        <tr>
                          <th style="width: 200px;">Procedimento</th>
                          <th style="width: 200px;" v-for="plano in ch" :key="plano.id">
                            {{ plano.nome }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(procedimento, index) in proc_diferenciado.procs" :key="index">
                          <td style="min-width: 200px;">{{ procedimento.nome }}</td>
                          <td style="min-width: 200px;" v-for="plano in ch" :key="plano.id">
                            {{ procedimentoDiferenciado(plano.id, procedimento) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </div>
            <p v-else-if="!loadingProcedimentos" class="text-center h5 mt-4">
              <i class="ti-info-alt h4"></i> <br>
              Nenhuma procedimento obrigatório encontrado!
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="showDocs" class="page">
        <b-col cols="12 border mt-1">
          <h4>Docs</h4>
          <div class="text-center">
            <loader
              :width="'150px'"
              :height="'150px'"
              v-if="loadingDocs" />
          </div>
          <div class="docs" role="tablist" v-if="docsIsArray.length > 0">
            <div v-for="(doc, dindex) in filterDocs" :key="dindex">
              <h5 class="mb-1" :inner-html.prop="doc.catNome" />
              <div class="mb-1 table-responsive" :inner-html.prop="doc.itemConteudo" />
            </div>
          </div>
          <p v-else-if="!loadingDocs" class="text-center h5 mt-4">
            <i class="ti-info-alt h4"></i> <br>
            Nenhum documento encontrado!
          </p>
        </b-col>
      </b-row>
      <b-row v-if="showInfos" class="page">
        <b-col cols="12 border mt-1">
          <h4>+Infos</h4>
          <div class="text-center">
            <loader
              :width="'150px'"
              :height="'150px'"
              v-if="loadingInfos" />
          </div>
          <div class="infos" role="tablist" v-if="infosIsArray.length > 0">
            <div v-for="(info, iindex) in filterInfos" :key="iindex">
              <h5 class="mb-2" :inner-html.prop="info.catNome" />
              <div class="mb-3 table-responsive" :inner-html.prop="info.itemConteudo" />
            </div>
          </div>
          <p v-else-if="!loadingInfos" class="text-center h5 mt-4">
            <i class="ti-info-alt h4"></i> <br>
            Nenhuma informação encontrada!
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12 border mt-2 mb-5">
          <h4 class="text-danger">IMPORTANTE - LEIA COM ATENÇÃO</h4>
          <div class="card-aviso text-danger">
            <p class="fz-12">As informações referentes a Preços, Redes, Carências, Documentações, Reembolsos e Condições Contratuais são <b>determinadas pelas</b> operadoras, seguradoras, 
                administradoras ou outras entidades responsáveis e <b>deverão ser verificadas e conferidas junto</b> à respectiva <b>antes da CONTRATAÇÃO.</b></p>
            <p class="fz-12">Esta é uma <b>ferramenta de simulação</b> destinada a auxiliar no processo de pesquisa. Contudo, é importante destacar que <b>não nos responsabilizamos por possíveis 
            alterações que sejam feitas sem prévio aviso</b>. Para maiores informações e rede credenciada, consulte o site oficial da operadora. Quaisquer outras <b>dúvidas consulte o 
            corretor</b> que lhe enviou esta simulação.</p>
            <p class="fz-12"><b>Ressaltamos o nosso compromisso com a precisão das informações fornecidas</b>, no entanto, reservamo-nos o direito de corrigir eventuais erros identificados, esclarecendo 
            que tais correções <b>não estão vinculadas à efetivação do serviço</b>, que ocorrerá exclusivamente mediante a assinatura do contrato.</p>
            <p class="fz-12"><i>Aos valores apresentados não estão sendo somadas as taxas (exceto quando informado).</i></p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </overlay>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Overlay from '@/components/Overlay'
import Loader from '@/components/Loader'

export default {
  props: ['id'],
  data() {
    return {
      user: {
        name: '',
        login: '',
        logotipo: '',
        empresa: ''
      },
      filters: [],
      filtros: [],
      operadora: {
        nome: '',
        logotipoavo: '',
        logotipo: '',
        cidade: { id: '', cidade: '', uf: '' },
        tipo_plano: 's',
        coparticipacao: 2,
        compulsorio: 2,
        acomodacao: 2,
      },
      produto: {
        id: '',
        nome: ''
      },
      regioes: [],
      grupos: [],
      planos: [],
      planosPorRede: [],
      reembolsos: [],
      carenciasIsArray: [],
      carenciasHtml: '',
      proc_obrigatorios: [],
      proc_diferenciados: [],
      docsIsArray: [],
      docsHtml: '',
      infosIsArray: [],
      infosHtml: '',
      loadingCotacao: false,
      loadingSession: false,
      loadingValores: false,
      loadingRedes: false,
      loadingRegiao: false,
      loadingReembolsos: false,
      loadingCarencias: false,
      loadingProcedimentos: false,
      loadingDocs: false,
      loadingInfos: false,
      globalListaObs: [],
      globalListaObsKeys: []
    }
  },
  components: {
    Overlay,
    Loader
  },
  methods: {
    ...mapActions('usuario', ['readSession']),
    ...mapActions('share', ['cotacao']),
    ...mapActions('regiao', ['buscaRegiao']),
    ...mapActions('rede', ['buscaRede']),
    ...mapActions('reembolso', ['buscaReembolso']),
    ...mapActions('carencia', ['buscaCarencia']),
    ...mapActions('procedimento', ['buscaProcedimentos']),
    ...mapActions('docs', ['buscaDocs']),
    ...mapActions('info', ['buscaInfos']),
    chunk(arr, size) {
      return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
      );
    },
    distinct(x, i, a) {
      return a.indexOf(x) === i
    },
    checked(filters, type) {
      let ops = [...filters]
      let filter = ops.filter(filter => filter.data.item == type)
      let obj = filter.length > 0 ? filter.shift(1) : {}
      return obj.hasOwnProperty('state') ? obj.state.indeterminate || obj.state.checked : false
    },
    normalizeFields(fields) {
      return fields.map(f => {
        if (f.hasOwnProperty('isRowHeader')) {
          return {
            isRowHeader: parseInt(f.isRowHeader) ? true : false,
            key: f.key,
            stickyColumn: parseInt(f.stickyColumn) ? true : false,
          }
        }
        return f
      })
    },
    showFaixa(min, max) {
      let ops = [...this.filters]
      let filter = ops.filter(filter => filter.data.item == 'valor')
      let obj = filter.length > 0 ? filter.shift(1) : {}
      let children = [...obj.children]
      let filterChildren = children.filter(chil => chil.data.min == min && chil.data.max == max)
      let objChildren = filterChildren.length > 0 ? filterChildren.shift(1) : {}
      return objChildren.hasOwnProperty('state') ? objChildren.state.checked : false
    },
    async recuperaReembolsos() {
      this.loadingReembolsos = true
      await this.buscaReembolso({
        uf: this.operadora.cidade.uf,
        produto: this.operadora.produto.id,
        tipo_plano: this.operadora.tipo_plano,
        contratacao: this.operadora.contratacao == 'a' ? 'pf' : this.operadora.contratacao,
        cidade: this.operadora.cidade ? this.operadora.cidade.id : '',
        compulsorio: this.operadora.compulsorio == 2 ? '' : this.operadora.compulsorio == 1 ? 's' : 'n',
        coparticipacao: this.operadora.coparticipacao == 2 ? '' : this.operadora.coparticipacao == 1 ? 's' : 'n',
        acomodacao: this.operadora.acomodacao == 2 ? '' : this.operadora.acomodacao == 1 ? 'a' : 'e',
        credenciado: this.operadora.credenciado ? this.operadora.credenciado.id : '',
        planos: this.planos.map(p => p.id)
      }).then(reembolsos => {
        this.loadingReembolsos = false
        this.reembolsos = reembolsos
      }).catch(err => { this.loadingReembolsos = false })
    },
    semReembolso(planoId) {
      return this.reembolsos.findIndex((r) => r.plId == planoId) == -1
    },
    reembolsoValores(planoId, reembolsoId) {
      let plKey = this.reembolsos.findIndex((r) => r.plId == planoId)
      let valor = 0.00
      if (plKey !== -1) {
        let plano = this.reembolsos[plKey]
        let vKey = plano.valores.findIndex((v) => v.aId == reembolsoId)
        if (vKey !== -1) {
          let reembolso = this.reembolsos[plKey].valores[vKey]
          valor = `${parseFloat(reembolso.valor).toFixed(2)}`.replace('.', ',')
        } else {
          valor = 'Não Inf.'
        }
      } else {
        valor = '-'
      }
      return valor
    },
    async recuperaRedes() {
      this.loadingRedes = true
      let redes = this.grupos.map(grupo => grupo.redes)
        .reduce((acc, i) => acc.concat(i), [])
        .filter((value, index, self) => self.indexOf(value) === index)
      await this.buscaRegiao({
        uf: this.operadora.cidade.uf,
        cidade: '',// this.operadora.cidade ? this.operadora.cidade.id : '',
        credenciado: this.operadora.credenciado ? this.operadora.credenciado.tipo + this.operadora.credenciado.id : '',
        redes: redes
      }).then(regioes => {
        this.loadingRedes = false
        this.regioes = regioes
      }).catch(err => { this.loadingRedes = false })
    },
    async recuperaCarencia() {
      this.loadingCarencias = true
      await this.buscaCarencia({
        uf: this.operadora.cidade.uf,
        produto: this.produto.id,
        tipo_plano: this.operadora.tipo_plano,
        contratacao: this.operadora.contratacao == 'a' ? 'pf' : this.operadora.contratacao,
      }).then(carencias => {
        this.loadingCarencias = false
        if (Array.isArray(carencias)) {
          this.carenciasIsArray = carencias
        } else {
          this.carenciasHtml = carencias
        }
      }).catch(err => this.loadingCarencias = false)
    },
    procedimentoObrigatorio(planoId, proc) {
      let procKey = proc.planos.findIndex((p) => p.plId = planoId)
      return procKey !== -1 ? 'SIM' : 'NÃO'
    },
    procedimentoDiferenciado(planoId, proc) {
      let procKey = proc.planos.findIndex((p) => p.plId = planoId)
      return procKey !== -1 ? 'SIM' : 'NÃO'
    },
    async recuperaProcedimentos() {
      this.loadingProcedimentos = true
      await this.buscaProcedimentos({
        uf: this.operadora.cidade.uf,
        planos: this.planos.map(p => p.id)
      }).then(rprocedimentos => {
        this.proc_obrigatorios = rprocedimentos.obrigatorios
        this.proc_diferenciados = rprocedimentos.opcionais
        this.loadingProcedimentos = false
      }).catch(err => this.loadingProcedimentos = false)
    },
    async recuperaDocs() {
      this.loadingDocs = true
      await this.buscaDocs({
        uf: this.operadora.cidade.uf,
        produto: this.produto.id,
        tipo_plano: this.operadora.tipo_plano,
        contratacao: this.operadora.contratacao == 'a' ? 'pf' : this.operadora.contratacao,
      }).then(docs => {
        this.loadingDocs = false
        if (Array.isArray(docs)) {
          this.docsIsArray = docs
        } else {
          this.docsHtml = docs
        }
      }).catch(err => { this.loadingDocs = false })
    },
    async recuperaInfo() {
      this.loadingInfos = true
      await this.buscaInfos({
        uf: this.operadora.cidade.uf,
        produto: this.produto.id,
        tipo_plano: this.operadora.tipo_plano,
        contratacao: this.operadora.contratacao == 'a' ? 'pf' : this.operadora.contratacao,
        planos: this.planos.map(p => p.id),
        administradora: this.administradora ? this.administradora.id : '',
        entidade: this.entidade ? this.entidade.id : '',
      }).then(infos => {
        this.loadingInfos = false
        if (Array.isArray(infos)) {
          this.infosIsArray = infos
        } else {
          this.infosHtml = infos
        }
      }).catch(err => { this.loadingInfos = false })
    },
    cabCredPlanos(credenciados) {
      let redes = Object.values(credenciados)
        .flat()
        .map(c => c.redes)
        .flat()
        .filter(this.distinct)
      let planos = [...this.planosPorRede]
      return planos.filter(plano => redes.indexOf(plano.rede_id) != 1)
    },
    cobCredPlanos(plano, credenciado) {
      return credenciado
        .redes
        .map(r => parseInt(r))
        .indexOf(parseInt(plano.rede_id)) != -1 ?
          credenciado.cob[plano.rede_id].split(',').map(c => `<span>${c}</span>`).join(',') :
          ' - '
    },
    cobObsPlanos(plano, credenciado) {
      if(!credenciado.hasOwnProperty('obs') || ! credenciado.obs[plano.rede_id]) {
        return;
      }
      
      let id = 0;
      let key = credenciado.obs[plano.rede_id].replace(/[^a-zA-Z0-9]/gi, '');
      let index = this.globalListaObsKeys.indexOf(key);

      if(index == -1) {
        let obs = credenciado.obs[plano.rede_id];
        obs = obs.replace(/(\<br \/\>|\<br\>)/gi, ', ');
        
        this.globalListaObs.push(obs);
        this.globalListaObsKeys.push(key)
        id = this.globalListaObs.length +1;
      } else {
        id = index+1;
      }
      return ` <span class="small">[${id}]</span>`
    },
    cobObsPlanosPrintAll() {
      let html = '';
      this.globalListaObsKeys.forEach((key, index) => {
        let c = index +1;
        html += `<li>[${c}]: ${this.globalListaObs[index]}</li>`
      })
      return html;
    },
    slugify: text => {
      text ? text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-') : ''
    },
    checked(filters, type) {
      let ops = [...filters]
      let filter = ops.filter(f => f.data.item == type)
      let obj = filter.length > 0 ? filter.shift(1) : {}
      return obj.hasOwnProperty('state') ? obj.state.indeterminate || obj.state.checked : false
    },
    children(filters, key) {
      let filter = filters.find(f => f.data.item == key)
      return filter ? filter.children : []
    },
    valoresKey() {
      // Define a chave que sera utilizada no objeto grupo para exibição da tabela
      return this.valores.indexOf('00-18') == -1 ? 'plano' : 'idade'
    }
  },
  computed: {
    ...mapGetters('usuario', ['getSession']),
    ...mapGetters('share', ['getCotacao']),
    ...mapGetters('operadora', ['getOperadora', 'getProduto', 'getPlanos', 'getPlanosPorRede', 'getGroupTree', 'getGrupos']),
    ...mapGetters('modalFilter', ['getFilters']),
    textCop() {
      return this.operadora.coparticipacao > 1 ? 'indiferente' : this.operadora.coparticipacao == 1 ? 'sim' : 'não'
    },
    textCom() {
      return this.operadora.compulsorio > 1 ? 'indiferente' : this.operadora.compulsorio == 1 ? 'sim' : 'não'
    },
    textAcom() {
      return this.operadora.acomodacao > 1 ? 'indiferente' : this.operadora.acomodacao == 1 ? 'apartamento' : 'enfermaria'
    },
    operadoraLogoTipo() {
      return this.operadora.logotipoavo ? this.operadora.logotipoavo : this.operadora.logotipo
    },
    tipoPlano() {
      let tipo_plano = ''
      switch (this.operadora.tipo_plano) {
        case 'o':
          tipo_plano = 'Odonto'
          break;
        case 'a':
          tipo_plano = 'Adesão'
          break;
        case 's':
        default:
          tipo_plano = 'Saúde'
          break;
      }
      return tipo_plano
    },
    subtitle() {
      let subtitle = ''
      subtitle += (this.operadora.tipo_plano != 'o') ? 'Saúde' : 'Odonto'
      subtitle += (this.operadora.contratacao == 'a') ? ' - Adesão' : (this.operadora.contratacao == 'pf' ? ' - PF' : ' - PME')
      subtitle += (this.operadora.administradora) ? ` - ${this.operadora.administradora.nome}` : ''
      subtitle += (this.operadora.entidade) ? ` - ${this.operadora.entidade.nome}` : ''
      subtitle += (this.operadora.credenciado) ? ' - ' + this.operadora.credenciado.nome : ''
      return subtitle
    },
    contratacao() {
      return this.operadora.contratacao.toUpperCase()
    },
    showValores() {
      return this.filters.length > 0 ? this.checked(this.filters, 'valor') : false
    },
    showRedes() {
      return this.filters.length > 0 && this.operadora.tipo_plano != 'o' ? this.checked(this.filters, 'rede') : false
    },
    showReembolsos() {
      return this.filters.length > 0 ? this.checked(this.filters, 'reembolso') : false
    },
    showCarencias() {
      return this.filters.length > 0 ? this.checked(this.filters, 'carencia') : false
    },
    showProcObrigatorios() {
      return this.filters.length > 0 && this.operadora.tipo_plano == 'o' ? this.checked(this.filters, 'proc_obrigatorios') : false
    },
    showProcDiferenciados() {
      return this.filters.length > 0 && this.operadora.tipo_plano == 'o' ? this.checked(this.filters, 'proc_diferenciados') : false
    },
    showDocs() {
      return this.filters.length > 0 ? this.checked(this.filters, 'docs') : false
    },
    showInfos() {
      return this.filters.length > 0 ? this.checked(this.filters, 'infos') : false
    },
    reembolsosProcedimentos() {
      let self = this
      let reembolsos = JSON.parse(JSON.stringify(this.reembolsos));
      let filtersReembolsosChildren = this.children(this.filters, 'reembolso')
      let reembolsosReduce = reembolsos.reduce((acc, obj) => {
        if (!Array.isArray(acc)) acc = []
        obj.valores.forEach((r) => {
          let i = acc.findIndex(p => p.aId == r.aId)
          if (i == -1) {
              acc.push({ aId: r.aId, nome: r.nome })
          }
        })
        return acc
      })
      try{
        return reembolsosReduce
          .filter(r => self.checked(filtersReembolsosChildren, r.nome))
      } catch{

      }
      
    },
    filterValores() {
      let grupos = JSON.parse(JSON.stringify(this.grupos));
      let filtersValoresChildren = this.children(this.filters, 'valor')
      return grupos.map(grupo => {
        let data_inicio = new Date(grupo.data_inicio),
            now = new Date()
        data_inicio.setDate(data_inicio.getDate() + 1)
        grupo.data_inicio = data_inicio <= now ? false : grupo.data_inicio
        grupo.items = grupo.items.filter(
          i => this.checked(
            filtersValoresChildren,
            i[this.valoresKey()]
          )
        )
        return grupo
      })
    },
    filterRegioes() {
      let self = this
      let regioes = JSON.parse(JSON.stringify(this.regioes));
      let filterRedeChildren = this.children(this.filters, 'rede')
      regioes = regioes.filter(regiao => {
        if (!self.checked(filterRedeChildren, regiao.nome)) return false
        let filterRegiaoChildren = self.children(filterRedeChildren, regiao.nome)
        regiao.cidades = regiao.cidades.filter(cidade => {
          if (!self.checked(filterRegiaoChildren, cidade.nome)) return false
          let filterCidadeChildren = self.children(filterRegiaoChildren, cidade.nome)
          let hospitais = []
          let laboratorios = []
          let centros = []
          if (cidade.credenciados.hasOwnProperty('Hospitais') && self.checked(filterCidadeChildren, 'Hospitais')) {
            let filterHospitalChildren = self.children(filterCidadeChildren, 'Hospitais')
            hospitais = cidade.credenciados['Hospitais'].filter(cred => {
              if (!self.checked(filterHospitalChildren, cred.id)) return false
              return true
            })
          }
          if (cidade.credenciados.hasOwnProperty('Laboratórios') && self.checked(filterCidadeChildren, 'Laboratórios')) {
            let filterLaboratorioChildren = self.children(filterCidadeChildren, 'Laboratórios')
            laboratorios = cidade.credenciados['Laboratórios'].filter(cred => {
              if (!self.checked(filterLaboratorioChildren, cred.id)) return false
              return true
            })
          }
          if (cidade.credenciados.hasOwnProperty('Centros Médicos') && self.checked(filterCidadeChildren, 'Centros Médicos')) {
            let filterCentroMedicoChildren = self.children(filterCidadeChildren, 'Centros Médicos')
            centros = cidade.credenciados['Centros Médicos'].filter(cred => {
              if (!self.checked(filterCentroMedicoChildren, cred.id)) return false
              return true
            })
          }
          cidade.credenciados['Hospitais'] = hospitais
          if (hospitais.length == 0) delete cidade.credenciados['Hospitais']
          cidade.credenciados['Laboratórios'] = laboratorios
          if (laboratorios.length == 0) delete cidade.credenciados['Laboratórios']
          cidade.credenciados['Centros Médicos'] = centros
          if (centros.length == 0) delete cidade.credenciados['Centros Médicos']
          if (Object.values(cidade.credenciados).flat().length > 0) {
            return true
          }
          return false
        })
        if (regiao.cidades.length > 0) {
          return true
        }
        return false
      })
      return regioes
    },
    filterCarencia() {
      let carencias = JSON.parse(JSON.stringify(this.carenciasIsArray));
      let filtersCarenciaChildren = this.children(this.filters, 'carencia')
      return carencias
        .filter(c => this.checked(filtersCarenciaChildren, c.catNome))
    },
    filterProcObrigatorio() {
      let self = this
      let proc_obrigatorios = JSON.parse(JSON.stringify(this.proc_obrigatorios));
      let filtersProcObriChildren = this.children(this.filters, 'proc_obrigatorios')
      return proc_obrigatorios
        .filter(p => {
          if (!self.checked(filtersProcObriChildren, p.nome)) return false
          let filterProcChildren = self.children(filtersProcObriChildren, p.nome)
          p.procs = p.procs.filter(pr => self.checked(filterProcChildren, pr.nome))
          return p.procs.length > 0
        })
    },
    filterProcDiferenciado() {
      let self = this
      let proc_diferenciados = JSON.parse(JSON.stringify(this.proc_diferenciados));
      let filtersProcDifChildren = this.children(this.filters, 'proc_diferenciados')
      return proc_diferenciados
        .filter(p => {
          if (!self.checked(filtersProcDifChildren, p.nome)) return false
          let filterProcChildren = self.children(filtersProcDifChildren, p.nome)
          p.procs = p.procs.filter(pr => self.checked(filterProcChildren, pr.nome))
          return p.procs.length > 0
        })
    },
    filterDocs() {
      let docs = JSON.parse(JSON.stringify(this.docsIsArray));
      let filtersDocsChildren = this.children(this.filters, 'docs')
      return docs
        .filter(d => this.checked(filtersDocsChildren, d.catNome))
    },
    filterInfos() {
      let infos = JSON.parse(JSON.stringify(this.infosIsArray));
      let filtersInfosChildren = this.children(this.filters, 'infos')
      return infos
        .filter(i => this.checked(filtersInfosChildren, i.catNome))
    },
    loadingAll() {
      return !this.loadingCotacao &&
        !this.loadingSession &&
        !this.loadingValores &&
        !this.loadingRedes &&
        !this.loadingRegiao &&
        !this.loadingReembolsos &&
        !this.loadingCarencias &&
        !this.loadingProcedimentos &&
        !this.loadingDocs &&
        !this.loadingInfos
    }
  },
  async created() {
    let operadora = JSON.parse(localStorage.getItem('operadora'))
    let filtros = JSON.parse(localStorage.getItem('filtros'))

    let filters = []
    if (this.id == 'cotacao') {
      this.loadingCotacao = true
      await this.cotacao(this.$route.query.hash)
        .then(() => this.loadingCotacao = false)
        .catch(() => this.loadingCotacao = false)
      const cotacao = this.getCotacao
      operadora = cotacao.detalhes.operadora
      filters = operadora.filters
    } else {
      this.loadingSession = true
      await this.readSession({
        id: this.$route.query.hash
      }).then(() => this.loadingSession = false)
      .catch(() => this.loadingCotacao = false)
    }

    this.user = this.getSession ? this.getSession.user : operadora.user

    this.filtros = filtros ? filtros : operadora.filtros
    this.filters = this.getSession ? this.getSession.filters : filters
    this.operadora = this.getSession ? this.getSession : (this.getOperadora ? this.getOperadora : operadora)
    this.produto = this.getSession ? this.getSession.produto : (this.getProduto ? this.getProduto : operadora.produto)
    this.planos = this.getSession ? this.getSession.planos : (this.getPlanos.length > 0 ? this.getPlanos : operadora.planos)
    this.planosPorRede = this.getSession ? this.getSession.planosPorRede : (this.getPlanosPorRede.length > 0 ? this.getPlanosPorRede : operadora.planosPorRede)
    this.valores = this.getSession ? this.getSession.valores : (this.operadora.valores.length > 0 ? this.operadora.valores : operadora.valores)
    this.grupos = this.getSession ? this.getSession.grupos : (this.getGrupos.length > 0 ? this.getGrupos : operadora.grupos)

    this.operadora['grupos'] = this.grupos

    this.debouncedRede = this._.debounce(this.recuperaRedes, 350)
    this.debouncedReembolso = this._.debounce(this.recuperaReembolsos, 350)
    this.debouncedCarencia = this._.debounce(this.recuperaCarencia, 350)
    this.debouncedProcedimento = this._.debounce(this.recuperaProcedimentos, 350)
    this.debouncedDocs = this._.debounce(this.recuperaDocs, 350)
    this.debouncedInfos = this._.debounce(this.recuperaInfo, 350)

    if (this.showRedes) {
      await this.debouncedRede()
    }
    if (this.showReembolsos) {
      await this.debouncedReembolso()
    }
    if (this.showCarencias) {
      await this.debouncedCarencia()
    }
    if (this.showProcObrigatorios) {
      await this.debouncedProcedimento()
    }
    if (this.showDocs) {
      await this.debouncedDocs()
    }
    if (this.showInfos) {
      await this.debouncedInfos()
    }
    setTimeout(() => {
      window.print()
    }, 3000)
  }
}
</script>

<style lang="scss">
body {
  background-color: #fff !important;
}
table {
  th {
    font-size: 10px !important;
    padding: 5px !important;
  }
  td {
    font-size: 10px !important;
    padding: 5px !important;
  }
}
.impressao {
  h5 {
    border: 1px solid #ccc;
    padding: 5px;
  }
}
div.logo-corretora {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
div.info-plano-selecionado {
  display: inline-block;
  vertical-align: top;
  padding: 5px 8px;
  & h4 {
    margin: 0;
  }
  & h6 {
    font-weight: normal;
    margin-top: 4px;
  }
  & p {
    font-size: 12px;
    margin: 5px 0px;
  }
}
.carencias table,
.docs table,
.infos table {
  border: none;
}
.carencias .card-body > div > div,
.docs .card-body > div > div,
.infos .card-body > div > div {
  padding: 5px;
}
.infos p {
  font-size: 12px;
}
/*
.redes, .reembolsos {
  overflow: hidden !important;
}
*/
@media only screen and (max-width: 700px) {
  div.logo-corretora {
    margin-left: 0 !important;
  }
  div.logo-corretora .card-logo,
  div.logo-operadora .card-logo {
    width: 125px;
  }
}
@media print {
  div.page .border {
    border: none;
  }
  /*h2, h3, h4 {
    page-break-before: always;
  }*/
  h5, h6 {
    page-break-after: avoid;
  }
  pre, blockquote {
    page-break-inside: avoid;
  }
}
</style>
