import AdministradoraService from '@/services/administradora.service'

export default {
  namespaced: true,
  state: {
    administradora: null,
    administradoras: [],
  },
  mutations: {
    setAdministradora(state, administradora) {
      state.administradora = administradora
    },
    setAdministradoras(state, administradoras) {
      state.administradoras = administradoras
    }
  },
  actions: {
    buscaAdministradoras(
      { commit },
      {
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        entidade,
        profissao
      }
    ) {
      return AdministradoraService.busca(
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        entidade,
        profissao
      ).then(resp => {
        commit('setAdministradoras', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    }
  },
  getters: {
      getAdministradora(state) {
        return state.administradora
      },
      getAdministradoras(state) {
        return state.administradoras
      }
  }
}
