class LocalService {
    save({ key, value }) {
        let data = this.load(key)
        data[key] = value
        return new Promise((resolve) => {
        resolve(
            localStorage.setItem(
                process.env.VUE_APP_CLIENT_ID,
                JSON.stringify(data)
            )
        )
        })
    }
    load(key) {
        let data = JSON.parse(
            localStorage.getItem(
                process.env.VUE_APP_CLIENT_ID
            )
        )
        data = data ? data : {}
        let value = key ? data[key] : data
        return value ? value : {}
    }
}

export default new LocalService();