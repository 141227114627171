import Vue from 'vue'

class InfoService {
  // https://api.simp.agencialink.com/informacoes/:uf/:produto/:tipo_plano/:contratacao/
  busca(
    uf,
    produto,
    tipo_plano,
    contratacao,
    planos,
    administradora,
    entidade
  ) {
    let params = [
      { key: 'administradora', value: administradora },
      { key: 'entidade', value: entidade }
    ]
      .map(p => p.key+'='+(p.value||''))
      .join('&');
    let paramsPlanos = planos ? params + '&' + planos.map(plano => 'planos[]='+plano).join('&') : params;
    return Vue.prototype.$http.get(
      `informacoes/${uf}/${produto}/${tipo_plano}/${contratacao}?${paramsPlanos}`
    )
    .then(response => {
      return response.data;
    });
  }
}

export default new InfoService();
