import Vue from "vue";
import VueRouter from "vue-router";
import VueBodyClass from 'vue-body-class';
import routes from "./routes";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active"
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
  const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
  const json = JSON.parse(id)
  const user = json?.user
  if (to.name !== 'login' && to.meta.auth && !user) {
    next({ name: 'login' })
  } else if (to.name == 'login' && user) {
    if (to.query.hash) {
      next({ path: `/tabela/${to.query.hash}` })
    } else {
      next({ name: 'tabelas' })
    }
  } else if (user) {
    next()
  } else {
    next()
  }
})

export default router;
