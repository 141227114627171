<template>
  <div ref="device">
    <b-modal id="modal-share"
      ref="modal-share"
      class="note-toolbar card-header icheck-success d-inline"
      size="lg"
      button-size="sm"
      @show="showModalShare"
      @ok="handleOk">
      <template v-slot:modal-title>
        <font-awesome-icon :icon="['far', 'paper-plane']" /> Enviar ou Compartilhar
      </template>
      <template v-slot:default>
        <form ref="form-share" @submit.stop.prevent="handleSubmit" autocomplete="off">
          <b-row class="mb-2">
            <b-col sm="12" md="12" v-if="lead.id">
              <div class="card" style="background-color: #fff0c9;">
                <div class="card-body card-media-inicial p-1">
                  <div class="row justify-content-between media-inicial">
                      <div class="media">
                        <font-awesome-icon :icon="['fas', 'user-circle']" size="2x" style="color: #001f3f;" class="mt-1 mr-1" />
                        <div class="media-body">
                          <h6 class="mb-0">Envios ao Lead ID: <b>{{ lead.id }}</b></h6>
                          <p class="m-0">
                            {{ lead.nome}} <b>({{ lead.email}})</b>
                          </p>
                        </div>
                      </div>
                      <div>
                        <div class="confirm float-sm-right">
                          <a :href="linkB2Cor" target="_blank" class="btn btn-primary bg-navy  border-0  mr-1" title="Abrir">
                            <font-awesome-icon :icon="['fas', 'external-link-alt']" /> <span class="span-bt-mobile">Ver Lead</span>
                          </a>
                          <a class="btn btn-primary bg-navy border-0  mr-1" @click="desunir()" title="Desassociar">
                            <font-awesome-icon :icon="['fas', 'ban']" /> <span class="span-bt-mobile">Desassociar</span>
                          </a>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col sm="12"  md="6">
              <b-form-group
                :state="emailClienteState"
                :invalid-feedback="emailClienteInvalidFeedback"
                class="p-0 m-0">
                <template v-slot:label>
                  <label for="emailCliente" class="no-label p-0 m-0">
                    E-mail do Cliente: <b-icon icon="exclamation-circle-fill" variant="secondary" title="O email é a chave primaria para voce poder localizar o cadastro do Lead (indicação) no sistema B2COR CRM e assim associar/vincular esta simulação/cotação diretamente no Lead."></b-icon>
                    {{ indicacoes }}
                  </label>
                </template>
                <cool-select
                  id="clienteSearch"
                  ref="clienteSearch"
                  v-model="cliente"
                  :items="clientes"
                  :loading="emailClienteLoading"
                  item-text="email"
                  placeholder="E-mail do Cliente"
                  :reset-search-on-blur="false"
                  disable-filtering-by-search
                  @search="clientesSearch"
                >
                  <template #no-data>
                    {{
                      noData
                        ? "Nenhum cliente encontrado."
                        : "Buscar cliente."
                    }}
                  </template>
                  <template #item="{ item }">
                    <span v-html="item.label" @click="setSelectedCliente($event, item)"></span>
                  </template>
                   <template slot="input-end">
                    <div class="input-group-append">
                      <b-button variant="outline-input-default">
                        <b-icon icon="search" aria-hidden="true"></b-icon>
                      </b-button>
                    </div>
                  </template>
                </cool-select>
              </b-form-group>
              <b-form-checkbox
                id="b2cor"
                name="b2cor"
                value="nova"
                v-model="b2cor">
                Criar <b class="red">NOVO</b> Lead no B2cor <b-icon icon="exclamation-circle-fill" variant="secondary" title="Marque para criar um NOVO Lead (indicação) no sistema B2COR CRM e assim associar/vincular esta simulação/cotação diretamente no NOVO Lead."></b-icon>
              </b-form-checkbox>
            </b-col>
            <b-col sm="12" md="6">
              <b-form-group
                :state="nomeClienteState"
                small
                invalid-feedback="Nome é obrigatório"
                class="p-0 m-0">
                <template v-slot:label>
                  <label for="nomeCliente" class="no-label p-0 m-0">
                    Nome do Cliente:
                  </label>
                </template>
                <b-form-input
                  id="nomeCliente"
                  class="m-0"
                  v-model="nomeCliente"
                  placeholder="Nome do Cliente"
                  :state="nomeClienteState"
                  required
                  autocomplete="new-password"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="12" md="6" class="mb-2">
              <b-form-group class="p-0 m-0">
                <template v-slot:label>
                  <label for="emailCopias" class="no-label p-0 m-0">
                    Enviar Cópia Para: <b-icon icon="exclamation-circle-fill" variant="secondary" title="Voce pode Inserir até 5 endereços (Exemplo: email@dominio.com.br, email2@dominio.com.br) para que todos recebam uma cópia desta simulação/cotação."></b-icon>
                  </label>
                </template>
                <b-form-input
                  id="emailCopias"
                  class="m-0"
                  v-model="emailCopias"
                  placeholder="Exemplo: email@dominio.com.br, email2@dominio.com.br"
                  autocomplete="new-password"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6">
              <b-form-group
                class="p-0 m-0">
                <template v-slot:label>
                  <label for="whatsAppCliente" class="no-label p-0 m-0">
                    WhatsApp do Cliente: <b-icon icon="exclamation-circle-fill" variant="secondary" title="Insira aqui o numero do whatsapp do seu cliente para que voce possa enviar a simulação/cotação direto no whatsapp dele."></b-icon>
                  </label>
                </template>
                <b-input-group>
                  <b-form-input
                    id="whatsAppCliente"
                    class="m-0"
                    type="tel"
                    v-mask="'(##) #####-####'"
                    v-model="whatsAppCliente"
                    placeholder=" WhatsApp do Cliente"
                    autocomplete="new-password"></b-form-input>
                  <b-input-group-append>
                    <a :href="`tel:${rmMask(whatsAppCliente)}`"
                      class="btn btn-outline-input-default" target="_blank">
                      <b-icon icon="telephone-x" aria-hidden="true"></b-icon>
                    </a>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-form-group
                :state="assuntoState"
                invalid-feedback="Assunto é obrigatório"
                class="p-0 m-0">
                <template v-slot:label>
                  <div class="d-flex justify-content-between fz-12">
                    <label for="asssunto" class="no-label p-0 m-0">
                      Assunto: <b-icon icon="exclamation-circle-fill" variant="secondary" title="O assunto é um campo muito importante pois instiga o contato a abrir sua mensagem."></b-icon>
                    </label>
                    <label>
                      <a class="flip" style="color: #2196F3;cursor:pointer;" v-on:click="showModelos = !showModelos">
                        <font-awesome-icon :icon="['fas', 'folder-open']" />&nbsp;Modelos
                      </a>
                    </label>
                  </div>
                </template>
                <transition name="fade">
                  <b-row class="mb-2" v-if="showModelos">
                    <b-col sm="6" class="mb-2">
                      <b-form-select
                        class="form-control m-0"
                        style="color: #939ba2;"
                        v-model="msgCategoria"
                        :options="msgCategoriaOptions" />
                    </b-col>
                    <b-col sm="6">
                      <b-form-group class="m-0">
                        <b-input-group>
                          <b-form-select
                            class="form-control m-0"
                            style="color: #939ba2;"
                            v-model="msgModelo"
                            :disabled="!msgCategoria"
                            :options="msgModeloOptions" />
                          <b-input-group-append v-if="msgShowConfigurar">
                            <b-button variant="outline-input-default" @click="msgConfigurar">
                              <font-awesome-icon :icon="['fas', 'plus']" :style="{ color: '#28a745' }" />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </transition>
                <b-form-input
                  id="assunto"
                  class="note-icon-bar m-0"
                  v-model="assunto"
                  :state="assuntoState"
                  placeholder="Assunto"
                  autocomplete="new-password"
                  required></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <vue-editor v-model="msg" :editor-toolbar="toolbar" />
              <b-form-checkbox
                id="checkMsgPadrao"
                name="checkMsgPadrao"
                value="true"
                class="mt-2"
                v-model="checkMsgPadrao">
                <span class="fz-12">Não alterar a mensagem padrão</span> <b-icon icon="exclamation-circle-fill" variant="secondary" title="Marcando esta caixa, a mensagem acima não será gravada como uma NOVA Mensagem Padrão, ao fazer uma nova simulação o sistema sempre traz como sugestão sua mensagem padrão."></b-icon>
              </b-form-checkbox>
              <b-form-checkbox
                id="receberCopia"
                name="receberCopia"
                value="true"
                class="mt-2"
                v-model="receberCopia">
                <span class="fz-12">Receber cópia</span> <b-icon icon="exclamation-circle-fill" variant="secondary" title="Marque para receber uma cópia do e-mail enviado"></b-icon>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col><hr class="m-1"></b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-table
                striped
                ref="selectableTable"
                responsive
                small
                selectable
                :select-mode="arqSelectMode"
                @row-selected="onRowArqSelected"
                :fields="arqColunas"
                :items="arquivos">
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key">
                </template>
                <template v-slot:thead-top>
                  <b-tr>
                    <b-th colspan="4" class="text-center">
                      <h4 class="fw-normal fz-20 m-1">
                        Arquivos Preferenciais  <sup class="fz-14"><b-icon icon="exclamation-circle-fill" small variant="secondary" title="Os arquivos preferenciais são anexos ja gravados no sistema que facilita voce anexar em suas simulações/cotações aos seus clientes. (ex: apresentação da sua corretora ou arquivos/aditivos contratuais)."></b-icon></sup>
                      </h4>
                    </b-th>
                  </b-tr>
                </template>
                <template v-slot:head(visualizar)>
                  <div class="text-nowrap">Ver</div>
                </template>
                <template v-slot:head(nome)>
                  <div class="text-nowrap">Arquivo</div>
                </template>
                <template v-slot:cell(visualizar)="scope">
                  <div class="text-nowrap bold-icon" >
                    <a :href="scope.value" target="_blank">
                      <b-icon icon="pencil-square" class="fz-14" />
                    </a>
                  </div>
                </template>
                <template v-slot:head(selected)>
                  <div class="text-nowrap text-right">Selecionado</div>
                </template>
                <template v-slot:cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                  </template>
                </template>
              </b-table>
              <p class=" txt-h5-font text-left m-0">
                <a v-b-modal.modal-file>
                  <b-icon icon="paperclip" variant="secondary"></b-icon> Inserir Novo Arquivo.
                </a>
              </p>
            </b-col>
          </b-row>
        </form>
      </template>
      <template v-slot:modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="ok()" :disabled="enviandoCotacao">
          <b-spinner small variant="white" v-if="enviandoCotacao" ></b-spinner>
          <font-awesome-icon :icon="['far', 'paper-plane']" v-else /> Salvar/Enviar
        </b-button>
      </template>
    </b-modal>
    <b-modal id="modal-file"
      size="lg"
      button-size="sm"
      ok-only
      ok-title="Fechar"
      ok-variant="success">
      <UploadFiles @updateFiles="files = $event.files"  />
    </b-modal>
    <b-modal id="modal-link"
      ref="modal-link"
      class="note-toolbar card-header icheck-success d-inline modal-dialog-centered"
      button-size="sm"
      @hidden="resetModalLink">
      <template v-slot:modal-title>
        <font-awesome-icon :icon="['far', 'paper-plane']" /> Enviar e compartilhar
      </template>
      <template v-slot:default>
        <form ref="form-link" autocomplete="off">
          <b-row class="mb-1">
            <b-col sm="12">
              <b-alert :variant="alertType" :show="alertShow">
                {{ alertMsg }}
              </b-alert>
            </b-col>
          </b-row>
          <b-row class="text-center">
            <b-col sm="6" class="text-center text-md-right">
              <label class="mr-sm-6 text-left icon-info" for="inline-form-input-name">
                <h4 class="m-0"><b>Link da Cotação:</b></h4>
                <h5><span class="fw-normal fz-15">Código Cotação: </span><b>{{ cotacao }}</b></h5>
              </label>
            </b-col>
            <b-col cols="8" sm="4" class="text-center">
              <b-input
                id="linkCurto"
                class="mb-2 mr-sm-2 mb-sm-0 form-control text-primary"
                v-model="linkCurto"
                @click.stop.prevent="copyToClipboard"
              ></b-input>
            </b-col>
            <b-col cols="4" sm="2" class="text-center text-sm-left pt-2 pl-0">
              <p class="text-primary fz-14">
                <b-link href="#" class="mr-2" style="color: #636363 !important;font-size:1.5rem;font-weight:510" @click.stop.prevent="copyToClipboard" title="Clique para copiar o link">
                  <b-icon icon="files" aria-hidden="true"></b-icon>
                </b-link>
                <b-link :href="linkCurto" target="_blank" style="color: #636363 !important;font-size:1.5rem;font-weight:510" title="Clique para abrir a cotação">
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </b-link>
              </p>
            </b-col>
          </b-row>
          <b-row class="text-center" style="border: 1px solid #ccc; padding: 20px; border-radius: 10px; margin: 0;">
            <b-col sm="12">
              <p class="fz-14">Utilize este campo caso deseje inserir uma data limite da cotação ao seu cliente</p>
            </b-col>
            <b-col sm="12">
              <label class="mr-1">
                Validade da Cotação: <b-icon icon="exclamation-circle-fill" variant="secondary" title="Utilize este campo caso deseje inserir uma data limite da cotação ao seu cliente."></b-icon>
              </label>
              <input type="date"
                class="w-35 d-inline-block"
                name="cotacao_validade"
                v-model="validade"/>
              <b-button
                class="btn-validade ml-1"
                size="sm"
                variant="success"
                @click="atualizarValidade">
                <b-spinner small variant="success" v-if="atuaizandoCotacao"></b-spinner>
                <b-icon icon="arrow-repeat" aria-hidden="true" v-else></b-icon>
              </b-button>
            </b-col>
            <b-col sm="12">
                <p class="text-center text-danger fz-14">
                *  cotação sem data de validade terá o prazo de 60 dias
                <a class="text-danger" href="#" title="Por medida de segurança, o sistema não permitirá que os clientes acessem cotações antigas.">
                    <font-awesome-icon :icon="['fas', 'question-circle']" />
                </a>
              </p>
            </b-col>
          </b-row>

        </form>
      </template>
      <template v-slot:modal-footer  :ok-disabled="true">
        <b-row class="w-100">
          <b-col sm="12 text-center">
            <b-button id="btn-enviar-email" :class="{ disabled: typeof emailCliente != 'string' || emailCliente.length < 3 }" size="sm" variant="success" title="Clique aqui para enviar por e-mail" @click="enviarEmail" :disabled="enviandoEmail">
              <b-spinner small variant="white" v-if="enviandoEmail"></b-spinner>
              <b-icon icon="envelope" style="color: #ffffffff;" v-else></b-icon>
              <span class="d-none d-sm-block">
                  Enviar
              </span>
            </b-button>
            <b-button id="btn-whatsapp" :class="{ disabled: whatsAppCliente.length < 14 }" title="Clique aqui para enviar por whatsapp" @click="enviarWhatsapp">
              <font-awesome-icon :icon="['fab', 'whatsapp']" />
            </b-button>
            <!-- <AddThis publicId="ra-5c1bb2228784ca0a"
              :data-url="linkCurto"
              class="addthis_inline_share_toolbox_2s4t"
              data-title="Link da sua cotação de plano de saúde" /> -->
            <ShareThis :url="linkCurto" />
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
const Block = Quill.import('blots/block');
class DivBlock extends Block {} 
DivBlock.tagName = 'DIV';
Quill.register('blots/block', DivBlock, true);

import UploadFiles from "@/components/UploadFiles"
// import AddThis from 'vue-simple-addthis-share'
import ShareThis from '@/components/ShareThis'
import Datepicker from 'vuejs-datepicker'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import { CoolSelect } from "vue-cool-select";

import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    callback: Function
  },
  components: {
    VueEditor,
    UploadFiles,
    // AddThis,
    ShareThis,
    Datepicker,
    CoolSelect
  },
  data() {
    const l = JSON.parse(localStorage.getItem('lead_associado'));
    const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
    const json = JSON.parse(id)
    const user = json?.user
    
    return {
      hash: '',
      deviceWidth: 0,
      deviceHeight: 0,
      enviandoCotacao: false,
      enviandoEmail: false,
      atuaizandoCotacao: false,
      copy: false,
      cotacao: '',
      linkCurto: '',
      link : '',
      user: null,
      files: [],
      lead: {
        id: l != null ? l.id_cliente : null,
        nome: l != null ? l.nome : null,
        email: l != null ? l.email : null
      },
      showModelos: false,
      indicacoes: '',
      linkB2Cor: l != null ? `https://barra.agencialink.com.br/autologin/${user.user_token}/b2cor?lead_id=${l.id_cliente}` : null,
      cliente: {
        label: '',
        email: l != null ? l.email : null,
        nome: l != null ? l.nome : null,
        celular_ddd: l != null ? l.celular_ddd : null,
        celular_numero: l != null ? l.celular_numero : null
      },
      clientes: [],
      nomeCliente: l != null ? l.nome : '',
      nomeClienteState: null,
      noData: false,
      emailCliente: l != null ? l.email : '',
      emailClienteState: null,
      emailClienteLoading: false,
      emailClienteInvalidFeedback: 'Email é obrigatório',
      whatsAppCliente: l != null ? l.celular : '',
      emailCopias: '',
      emailCopiasState: null,
      b2cor: '',
      assunto: l != null ? 'Envio de cotação para '+l.nome : '',
      assuntoOld: '',
      assuntoState: null,
      emailModelos: [],
      msgCategoria: null,
      msgModelo: null,
      msg: "",
      msgOld: "",
      checkMsgPadrao: true,
      submitted: [],
      arqColunas: [ 'visualizar', 'nome', 'tamanho', 'selected'],
      validade: '',
      arquivos: [],
      arqSelectMode: 'multi',
      arqSelected: [],
      receberCopia: false,
      alertType: '',
      alertShow: false,
      alertMsg: '',
      ptBR: ptBR,
      temExtensao: false,
      isMobile: false,
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
        ["clean"] // remove formatting button
      ],
    }
  },
  methods: {
    ...mapActions('local', ['save']),
    ...mapActions('arquivo', ['buscaArquivosPreferenciais']),
    ...mapActions('share', ['enviarCotacao', 'atualizarCotacao', 'email']),
    ...mapActions('b2cor', ['buscaLeads', 'criarIndicacao', 'atualizarIndicacao', 'pegarLead']),
    ...mapActions('email', ['buscaModelos']),
    copyToClipboard() {
      let codeToCopy = document.querySelector('#linkCurto')
      codeToCopy.select()
      try {
        this.copy = document.execCommand('copy');
        if (this.copy) {
          this.alertType = 'success'
          this.alertShow = true
          this.alertMsg = 'Link copiado para área de transferência.'
        } else {
          this.alertType = 'warning'
          this.alertShow = true
          this.alertMsg = 'Não foi possivel copiar o link, tente utlizar o CTRL + V.'
        }
      } catch (err) {
        this.alertType = 'danger'
        this.alertShow = true
        this.alertMsg = 'Não foi possivel copiar o link!'
      }
      window.getSelection().removeAllRanges()
    },
    clientesSearch: _.debounce(function(search) { this.getClientes(search) }, 1000),
    async getClientes(query) {
      const lettersLimit = 3;
      this.emailCliente = query
      this.noData = false;
      if (query.length < lettersLimit) {
        this.clientes = [];
        this.emailClienteLoading = false;
        return;
      }
      this.emailClienteLoading = true;
      const res = await this.buscaLeads({ user: this.user.id, search: query})
        .then((clientes) => {
          clientes = Object.values(clientes)
          let leads = []
          clientes.forEach((c) => {
            if (leads.length == 0) {
              let count = clientes.length
              let label = count == 0 ? 'Nenhuma indicação localizada' :
                  (count == 1 ? '1 indicação localizada' : `${count} indicações localizadas`)
              this.indicacoes = label
            }
            let date = c.data
              ? c.data.replace(/([\d]{4})-([\d]{2})-([\d]{2}) ([\d]{2}):([\d]{2}):([\d]{2})/, '$3/$2/$1')
              : ''
            leads.push({
              "label": [c.nome, c.email, date, c.status_label].join(' - '),
              "value": c.id_cliente,
              "email": c.email,
              "nome": c.nome,
              "celular_ddd": c.celular_ddd,
              "celular_numero": c.celular_numero
            })
          })
          this.clientes = leads
          if (!this.clientes.length) this.noData = true;
          this.emailClienteLoading = false;
        })
    },
    setSelectedCliente(evt, cliente) {
      console.log(cliente)
      this.lead = {
        id: cliente.value ? cliente.value : cliente.id_cliente,
        nome: cliente.nome,
        email: cliente.email
      }
      this.emailCliente = cliente.email
      this.nomeCliente = cliente.nome
      let ddd = cliente.celular_ddd.replace(/\D/, '')
      let numero = cliente.celular_numero.replace(/\D/, '')
      this.whatsAppCliente = (ddd+numero)
      this.b2cor = false
      this.linkB2Cor = `https://barra.agencialink.com.br/autologin/${this.user.user_token}/b2cor?lead_id=${this.lead.id}`
    },
    desunir() {
      this.lead = {
        id: null,
        nome: null,
        email: null
      }
      localStorage.removeItem('cotacao')
      localStorage.removeItem('lead_associado')
      this.emailCliente = ''
      this.nomeCliente = ''
      this.emailCopias = ''
      this.whatsAppCliente = ''
      this.assunto = ''
      this.msg = this.user.mensagem_padrao
      this.$refs.clienteSearch.setSearchData('')
      this.clientes = []
      this.cliente = null
      this.indicacoes = ''
    },
    arqColunasWidth(key) {
      let width = '30%'
      if (key == 'visualizar') width = '10%'
      if (key == 'tamanho' || key == 'selected') width = '15%'
      return { width: width }
    },
    onRowArqSelected(items) {
      this.arqSelected = items
    },
    checkFormValidity() {
      const valid = this.$refs['form-share'].checkValidity()
      this.nomeClienteState = this.$refs['form-share'].nomeCliente.checkValidity()
      //this.emailClienteState = this.emailCliente.length > 0
      this.assuntoState = this.$refs['form-share'].assunto.checkValidity()
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    filterState(filters, key) {
      let filter = filters.find(f => f.data.item == key)
      return filter ? filter?.state.checked ? 1 : 0 : 0
    },
    async handleSubmit() {
      // this.emailCliente = typeof this.clientesSearch == "string" ? this.clientesSearch : this.emailCliente
      // Exit when the form isn't valid
      console.log(this.checkFormValidity())
      if (!this.checkFormValidity()) {
        return
      }

      const jsonOperadora = localStorage.getItem('operadora')
      const operadora = JSON.parse(jsonOperadora)
      this.operadora = this.getOperadora ? this.getOperadora : operadora
      this.produto = this.getProduto ? this.getProduto : operadora.produto
      const groupTree = this.getGroupTree.length > 0 ? this.getGroupTree : operadora.groupTree

      const jsonFiltros = localStorage.getItem('filtros')
      const filtros = JSON.parse(jsonFiltros)

      const jsonFilters = localStorage.getItem('filters')
      const filters = JSON.parse(jsonFilters)

      const cotacao_id = localStorage.getItem('cotacao_id')

      this.operadora['filtros'] = filtros
      this.operadora['filters'] = filters

      this.$delete(this.operadora, 'carencias')
      this.$delete(this.operadora, 'docs')
      this.$delete(this.operadora, 'infos')

      this.enviandoCotacao = true
      const dadosCotacao = {
        id: cotacao_id ? cotacao_id : '',
        url: process.env.VUE_APP_URL,
        assunto: this.assunto,
        nomeCliente: this.nomeCliente,
        emailCliente: this.emailCliente,
        whatsAppCliente: this.whatsAppCliente,
        indicacao: !this.b2cor ? this.lead.id : '',
        msg: this.msg,
        checkMsgPadrao: this.checkMsgPadrao ? 0 : 1,
        arqSelected: this.arqSelected,
        detalhes: {
          "uf": this.operadora.cidade.uf,
          "tipo_plano": this.operadora.tipo_plano,
          "contratacao": this.operadora.contratacao,
          "produto": [this.operadora.produto.id],
          "operadora": this.operadora,
          "mensagem": this.msg,
          "emailCopias": this.emailCopias,
          "lead": this.lead,
          "user": this.user
        },
        parametros: {
          "item_acomodacao": filtros.acomodacao,
          "item_compulsorio": filtros.compulsorio,
          "item_coparticipacao": filtros.coparticipacao,
          "item_carencia": this.filterState(filters, 'carencia'),
          "item_proc_obrigatorio": this.filterState(filters, 'proc_obrigatorios'),
          "item_proc_diferenciado": this.filterState(filters, 'proc_diferenciados'),
          "item_reembolso": this.filterState(filters, 'reembolso'),
          "item_rede": this.filterState(filters, 'rede'),
          "item_documentos": this.filterState(filters, 'docs'),
          "item_maisinfo": this.filterState(filters, 'infos'),
          "filtros": filtros,
        },
        opcoes: {
          "groupTree": groupTree,
          "filters": filters
        }
      };

      await this.enviarCotacao(dadosCotacao)
      .then(async (res) => {
        this.cotacao = res.id_cotacao
        this.linkCurto = res.url
        this.link = res.url_cotacao
        this.hash = res.hash

        if (this.lead.id) {
          await this.atualizarIndicacao({
            indicacao: this.lead.id,
            nome: this.nomeCliente,
            email: this.emailCliente,
            fone: this.whatsAppCliente,
            assunto: this.msg,
            cotacao: this.cotacao
          })
        } else if (this.b2cor) {
          await this.criarIndicacao({
            nome: this.nomeCliente,
            email: this.emailCliente,
            fone: this.whatsAppCliente,
            assunto: this.msg,
            cotacao: this.cotacao
          })
        }

        if (this.getIndicacao) {
          const indicacao = Object.values(this.getIndicacao)
          if (indicacao.length > 0) {
            this.lead = {
              id: indicacao[0].id_cliente,
              nome: indicacao[0].nome,
              email: indicacao[0].email
            }
            
            this.linkB2Cor = `https://barra.agencialink.com.br/autologin/${this.user.user_token}/b2cor?lead_id=${this.lead.id}`

            // Necessário atualizar as informações do Lead na Cotação já gerada.
            dadosCotacao.id = this.cotacao;
            dadosCotacao.detalhes.lead = this.lead;
            dadosCotacao.indicacao = this.lead.id;
            this.enviarCotacao(dadosCotacao);
          }
        }

        localStorage.setItem('cotacao', JSON.stringify({
          url: process.env.VUE_APP_URL,
          assunto: this.assunto,
          nomeCliente: this.nomeCliente,
          emailCliente: this.emailCliente,
          emailCopias: this.emailCopias,
          whatsAppCliente: this.whatsAppCliente,
          indicacao: !this.b2cor ? this.lead.id : '',
          msg: this.msg,
          checkMsgPadrao: this.checkMsgPadrao,
          arqSelected: this.arqSelected,
          lead: this.lead
        }))

        // Hide the modal manually
        this.enviandoCotacao = false
        console.log('enviandoCotacao')
        this.$nextTick(() => {
          this.$bvModal.hide('modal-share')
          this.$bvModal.show('modal-link')
          this.callback(this.cotacao, res.hash);
        })
      }).catch((err) => {
        this.enviandoCotacao = false
        console.log(err)
      })
    },
    async atualizarValidade() {
      this.atuaizandoCotacao = true
      await this.atualizarCotacao({
        id: this.cotacao,
        validade: this.validade
      }).then(() => {
        this.atuaizandoCotacao = false
        this.alertType = 'success'
        this.alertMsg = `Atulizado validade da cotação (${this.cotacao})!`
        this.alertShow = true
      }).catch(() => this.atuaizandoCotacao = false)
    },
    async enviarEmail() {
      if(typeof this.emailCliente != 'string' || this.emailCliente.length < 3) {
        this.alertType = 'danger'
        this.alertShow = true
        this.alertMsg = 'Informe corretamente o e-mail para poder usar esta função!'
        return false;
      }
      this.enviandoEmail = true
      await this.email({
        id: this.cotacao,
        cc: this.emailCopias,
        comentario: this.msg,
        copia_corretor: this.receberCopia
      }).then((res) => {
        this.enviandoEmail = false
        this.alertType = 'success'
        this.alertMsg = 'Mensagem enviada com sucesso.'
        this.alertShow = true
      }).catch(() => this.enviandoEmail = false)
    },
    enviarWhatsapp() {
      let numero = this.whatsAppCliente.replace(/[^0-9]/gi, '');
      if(numero.length < 8) {
        this.alertType = 'danger'
        this.alertShow = true
        this.alertMsg = 'Informe corretamente o telefone para poder usar esta função!'
        return false;
      }
      var decodeHTML = function (html) {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      };
      let mensagem = 'Link da sua cotação de plano de saúde '+this.linkCurto+"\r\n";
      mensagem += this.msg.replace(/<?\/?b>/gi, '*');
      mensagem = decodeHTML(mensagem);
      mensagem = mensagem.replace(/(\<br\>|<\/p>)/gi, '\r\n');//tags de quebra de linha <br> ou de paragrafo </p>
      mensagem = mensagem.replace(/<[a-zA-Z ='"-_:,;()]{0,}?>/gi, ' ');//tag de abertura com classe, id e style
      mensagem = mensagem.replace(/<[a-zA-z]{1,}>/gi, '');//tag de abertura simples, talvez não seja mais necessário
      mensagem = mensagem.replace(/<[a-zA-Z ]{0,}\/>/gi, '\r\n');//tags de abertura
      mensagem = mensagem.replace(/<\/[a-zA-Z0-9]{1,} ?>/gi, '');//tags de fechamento

      var url;
      if (this.isMobile || !this.temExtensao) {
        url = 'https://wa.me/55'+numero;
      } else {
        url = 'https://web.whatsapp.com/send?phone=55'+numero;
      }

      url += url.indexOf('?') === -1 ? '?' : '&';

      url += 'text='+encodeURIComponent(mensagem.trim());
      window.open(url, 'whatsapp');
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return 'n/a';
      const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10);
      if (i === 0) return `${bytes} ${sizes[i]})`;
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    formatDate(d) {
      let month = d.getMonth();
      let day = d.getDate().toString()
      let year = d.getFullYear()
      year = year.toString().substr(-2)
      month = (month + 1).toString()
      if (month.length === 1) month = "0" + month
      if (day.length === 1) day = "0" + day
      return year + '/' + month + '/' + day
    },
    rmMask(string) {
      return string.replace(/\D/ig, '')
    },
    now() {
      let d = new Date()
      let month = d.getMonth();
      let day = d.getDate().toString()
      let year = d.getFullYear()
      year = year.toString()
      month = (month + 1).toString()
      if (month.length === 1) month = "0" + month
      if (day.length === 1) day = "0" + day
      return year + '-' + month + '-' + day
    },
    imprimir() {
      const jsonOperadora = localStorage.getItem('operadora')
      const operadora = JSON.parse(jsonOperadora)
      this.operadora = this.getOperadora ? this.getOperadora : operadora

      const json = localStorage.getItem('filtros')
      const filtros = JSON.parse(json)

      let routeData = this.$router.resolve({
        name: 'tabelaDetalhePrint',
        params: {
          id: 'cotacao'
        },
        query: {
          tipo_plano: filtros.tipo_plano,
          contratacao: filtros.contratacao,
          compulsorio: filtros.compulsorio == 2 ? '' : filtros.compulsorio == 1 ? 's' : 'n',
          coparticipacao: filtros.coparticipacao == 2 ? '' : filtros.coparticipacao == 1 ? 's' : 'n',
          acomodacao: filtros.acomodacao == 2 ? '' : filtros.acomodacao == 1 ? 'a' : 'e',
          profissao: filtros.profissao ? filtros.profissao : '',
          cidade: filtros.cidade ? filtros.cidade.id : '',
          credenciado: filtros.credenciado ? filtros.credenciado : '',
          hash: this.hash
        }
      })
      window.open(routeData.href, '_blank');
    },
    updateDevice() {
      this.deviceWidth = this.$screen.mobileLandscape ? this.$screen.height : this.$screen.width;
      this.deviceHeight = this.$screen.mobileLandscape ? this.$screen.width : this.$screen.height;
    },
    msgDefault() {
      this.msg = this.user.mensagem_padrao
    },
    async loadCotacaoData() {
      const cotacao = JSON.parse(localStorage.getItem('cotacao'))
      if (cotacao) {
        this.lead = cotacao.lead
        
        this.linkB2Cor = `https://barra.agencialink.com.br/autologin/${this.user.user_token}/b2cor?lead_id=${this.lead.id}`
        this.emailCliente = cotacao.emailCliente
        this.nomeCliente = cotacao.nomeCliente
        this.emailCopias = cotacao.emailCopias
        this.whatsAppCliente = cotacao.whatsAppCliente
        this.assunto = cotacao.assunto
        this.arqSelected = cotacao.arqSelected.filter(a => a.selected == '1')
      }
    },
    async loadUserData() {
      const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
      const json = JSON.parse(id)
      const user = json?.user
      await this.$store.dispatch(
        'usuario/refresh',
        user.user_token
      )
      this.user = this.$store.state.usuario.usuario
    },
    async reloadLeadData() {
      const res = await this.pegarLead({ user: this.user.id, id: this.lead.id})
        .then((clientes) => {
          clientes = Object.values(clientes)
          
          if(clientes[0].celular_ddd == '' && clientes[0].fixo_ddd != '') {
            clientes[0].celular_ddd = clientes[0].fixo_ddd
            clientes[0].celular_numero = clientes[0].fixo_numero
            clientes[0].celular = clientes[0].telefone
          }
          this.setSelectedCliente(null, clientes[0])
        })
    },
    async arquivosPreferencias() {
      if (this.user) {
        await this.buscaArquivosPreferenciais({
          id_corretora: this.user.id
        })
        this.arquivos = this.getArquivosPreferenciais.map(a => {
          return {
            id: a.id,
            visualizar: a.visualizar,
            nome: a.nome,
            arquivo: a.nome,
            tamanho: this.bytesToSize(a.tamanho)
          }
        })
        this.arqSelected.forEach((arq, i) => {
          this.arquivos.push(arq)
        })
      }
    },
    async bvModalShown() {
      this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
        if (modalId == 'modal-share') {
          if (this.$refs.clienteSearch) {
            // Preenche novamente o campo e-mail com o e-mail já informado
            this.$refs.clienteSearch.setSearchData(this.emailCliente)
          }
          if (this.$refs.selectableTable) {
            this.arqSelected.forEach((arq, i) => {
              let arqv = this.arquivos.find(a => a.id == arq.id)
              if (arqv) {
                this.$refs.selectableTable.selectRow(i)
              } else {
                this.arquivos.push(arq)
              }
            })
          }
        }
      })
    },
    async setHash() {
      this.hash = this.$route.query.hash
    },
    async buscaEmailModelos() {
      await this.buscaModelos()
        .then((res) => this.emailModelos = res.categorias)
        .catch((er) => console.log(er))
    },
    msgConfigurar() {
      window.open(this.user.config_url, '_blank');
    },
    async showModalShare() {
      if(this.lead.id != null) {
        this.reloadLeadData()
      }
      await this.setHash()
      await this.loadUserData()
      await this.loadCotacaoData()
      await this.msgDefault()
      await this.bvModalShown()
      await this.arquivosPreferencias()
      await this.buscaEmailModelos()
      await this.verificarExtensao()
      document.querySelector('#clienteSearch input').value = this.emailCliente
      this.$refs.clienteSearch.setSearchData(this.emailCliente)
    },
    resetModalLink() {
      this.alertShow = false
    },
    verificarExtensao() {
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      try{
          chrome.runtime.sendMessage('jpifkbikhaakajfklldhhhfpakaflndi', { message: "version" },
          function (reply) {
              if (reply) {
                  if (reply.version) {
                      this.temExtensao = true;
                  }
              }
          });
      } catch{};
    }
  },
  computed: {
    ...mapGetters('arquivo', ['getArquivosPreferenciais']),
    ...mapGetters('operadora', ['getOperadora', 'getProduto', 'getGroupTree']),
    ...mapGetters('b2cor', ['getLeads', 'getLead', 'getIndicacao']),
    ...mapGetters('email', ['getCategoria', 'getCategorias']),
    msgCategoriaOptions() {
      let emailCategorias = Object.values(this.emailModelos),
          options = [{ value: null, text: 'Selecione uma Categoria' }]
      return emailCategorias.length > 0 ?  options.concat(emailCategorias.map((c) => {
        return { value: c.id, text: c.nome }
      })) : []
    },
    msgModeloOptions() {
      let modelos = []
      if (this.msgCategoria) {
        let emailModelos = Object.values(this.emailModelos[this.msgCategoria].modelos),
        options = [{ value: null, text: 'Selecione um Modelo' }]
        modelos = emailModelos.length > 0 ?  options.concat(emailModelos.map((m) => {
          return { value: m.id, text: m.titulo }
        })) : []
      }
      return modelos
    },
    msgShowConfigurar() {
      return typeof this.user.config_url != 'undefined' && this.user.config_url.length > 0 ? true : false
    }
  },
  watch: {
    '$screen.width'(current, old) {
      this.updateDevice();
    },
    '$screen.height'(current, old) {
      this.updateDevice();
    },
    b2cor() {
      if (this.b2cor) {
        this.lead = {
          id: null,
          nome: null,
          email: null
        }
        localStorage.removeItem('cotacao')
      }
    },
    nomeCliente() {
      this.assunto = `Envio de cotação para ${this.nomeCliente}`
    },
    files(files) {
      files.forEach(file => {
        if (file.raw_name) {
          this.arquivos.push({
            hash: file.raw_name,
            visualizar: process.env.VUE_APP_API_URL + '/download/' + file.raw_name,
            nome: file.client_name,
            arquivo: file.orig_name,
            tamanho: this.bytesToSize(file.file.size),
            selected: true
          })
        }
      })
    },
    msgCategoria(current, old) {
      if (!this.msgOld) {
        this.assuntoOld = this.assunto
        this.msgOld = this.msg
      }
      if (!current) {
        this.assunto = this.assuntoOld
        this.msg = this.msgOld
      }
    },
    msgModelo(current, old) {
      if (!this.msgOld) {
        this.assuntoOld = this.assunto
        this.msgOld = this.msg
      }
      if (current) {
        let modelos = this.emailModelos[this.msgCategoria].modelos
        this.assunto = modelos[current].assunto
        this.msg = modelos[current].conteudo
          .replace(/__NOME_CLIENTE__/, this.nomeCliente ? this.nomeCliente : '__NOME_CLIENTE__')
          .replace(/__EMAIL_CLIENTE__/, this.emailCliente ? this.emailCliente : '__EMAIL_CLIENTE__')
          .replace(/__NOME_CORRETORA__/, this.user.empresa ? this.user.empresa : '__NOME_CORRETORA__')
          .replace(/__URL_CORRETORA__/, this.user.site ? this.user.site : '__URL_CORRETORA__')
      } else {
        this.assunto = this.assuntoOld
        this.msg = this.msgOld
      }
    }
  },
  async mounted() {
    await this.setHash()
    await this.loadUserData()
    await this.loadCotacaoData()
    await this.msgDefault()
    this.updateDevice()
    this.verificarExtensao()
    await this.bvModalShown()
    await this.arquivosPreferencias()
    await this.buscaEmailModelos()
  }
}
</script>

<style>
.btn-outline-input-default {
  border: 1px solid lightgray !important;
  background-color: lightgray !important;
  color: #646464 !important;
  box-shadow: none;
}

.btn-outline-input-default:hover {
  background-color: lightgray !important;
  color: #646464 !important;
  border-color: lightgray !important;
  box-shadow: none;
}

table#arquivos>thead>tr>th,
table#arquivos>tbody>tr>td {
  font-size: 14px;
  font-weight: 600;
  padding: 5px !important;
}

.table-striped:active {
  padding: 0 !important;
  background: none !important;
  border: none;
}

.bold-icon {
  font-weight: bolder;
  font-size: 18px;
  color: grey !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 8px;
  box-shadow: 0 0 .11em grey;
  background: hsla(0, 0%, 50.2%, .11);

}

.btn-outline-input-default {
  border: 1px solid lightgray !important;
  background-color: lightgray !important;
  color: #646464 !important;
  box-shadow: none;
}

.btn-outline-input-default:hover {
  background-color: lighthgray !important;
  color: #646464 !important;
  border-color: lightgray !important;
  box-shadow: none;
}

table#arquivos>thead>tr>th,
table#arquivos>tbody>tr>td {
  font-size: 14px;
  font-weight: 600;
  padding: 5px !important;
}

#modal-share div.modal-body,
#modal-share div.list-group {
  height: auto !important;
}

#modal-share tr>td:last-child {
  text-align: right;
}

#modal-share tr>td>span {
  padding: 0 10px !important;
  background-color: #fff;
  color: #fff;
  font-size: 15px;
  text-align: center;
  border: 1px solid #28a745;
}
#modal-share tr>td>span:hover {
  border: 2px solid #28a745;
}

#modal-share tr.b-table-row-selected>td>span {
  padding: 1px 4px !important;
  background-color: #28a745;
  color: #fff;
  font-size: 15px;
  text-align: center;
}

#model-share .form-group {
  margin-bottom: 5px;
}
#model-share .form-group label {
  margin-bottom: 5px;
}
#model-share  .form-group .input-group {
  margin-bottom: 5px;
}

#modal-link .modal-dialog {
  width: auto;
  max-width: 650px;
}

#modal-link .btn-validade {
  width: 27px;
  height: 27px;
  vertical-align: top;
  padding: 0;
}

#modal-link {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  outline: 0;
}

.bold-icon {
  font-weight: bolder;
  font-size: 18px;
  color: grey !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 8px;
  box-shadow: 0 0 .11em grey;
  background: hsla(0, 0%, 50.2%, .11);

}

.arrow-repeat {
  width: 10px !important;
}

.box-size {
  width: 75%;
  position: relative;
  left: 16%;
}

.card-media-inicial {
  padding: 1.25rem;
}

.media-inicial {
  padding: 0 !important;
  margin: 0 !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.span-bt-mobile, .label {
  display: none;
}
.span-bt-mobile, .no-label {
  display: inline-block;
}
.addthis_inline_share_toolbox {
  display: inline-block;
}
#btn-enviar-email, #btn-whatsapp {
  height: 58px;
  color: #fff;
  background-color: #4bb685;
  border: none;
  vertical-align: top;
  width: 65px;
  margin: 0 8px 0 0;
  border-radius: 0;
}
#btn-whatsapp {
  background-color: #4dc247;
  font-size: 2.5rem;
  line-height: 18px;
}
#btn-enviar-email.disabled, #btn-whatsapp.disabled {
  background-color: #333;
}
@media only screen and (max-width: 700px) {
  .box-size {
    width: 0%;
    position: relative;
    left: 10%;
  }
  .span-bt-mobile, .label {
    display: inline-block;
  }
  .span-bt-mobile, .no-label {
    display: none;
  }
  #modal-share .form-group {
    margin-bottom: 2px;
  }
  #modal-share .form-control {
    margin-bottom: 2px;
  }
  #modal-share .form-group .input-group {
    margin-bottom: 2px;
  }
  #modal-share .form-group .input-group .form-control {
    margin-bottom: 0px;
  }
  #modal-share tr>th:nth-child(3),
  #modal-share tr>td:nth-child(3) {
    display: none;
  }
  #modal-link .modal-title {
    font-size: 1.2em;
  }
  #modal-link .modal-title .fa-paper-plane {
    display: none;
  }
  #modal-link button .imprimir {
    display: none;
  }
  .addthis_inline_share_toolbox a.at-icon-wrapper {
    height: 39px !important;
    width: 39px !important;
    padding: 0;
    margin: 0;
  }
  .addthis_inline_share_toolbox a svg.at-icon {
    height: 35px !important;
    width: 35px !important;
    overflow: hidden;
    vertical-align: top;
  }
  .at-resp-share-element .at-share-btn .at-icon-wrapper {
    line-height: 44px !important;
    height: 39px !important;
    width: 39px !important;
  }
  .addthis_inline_share_toolbox .at-resp-share-element .at-share-btn {
    margin-right: 2px;
  }
  #btn-enviar-email, #btn-whatsapp
  {
    height: 39px!important;
    width: 39px!important;
    padding: 0;
    margin: 0 7px 0 0;
  }
  .svg-inline--fa {
    height: 0.5em !important;
    vertical-align: 0.05em !important;
  }
}

.addthis_inline_share_toolbox .at-resp-share-element.at-mobile .at-share-btn {
    margin-right: 2px;
}

@media screen and (max-width: 575px) {
  .media-inicial .media-body h6 {
    font-size: 11px;
  }
  .media-inicial .media-body p {
    font-size: 10px;
  }
  .media-inicial .btn.btn-primary.bg-navy {
    padding: 5px 7px;
  }
  .quillWrapper .ql-snow.ql-toolbar {
    display: none;
  }
  .ql-toolbar.ql-snow+.ql-container.ql-snow {
    border-top: 1px solid #ccc !important;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
