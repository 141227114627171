<template>
  <div :class="{'nav-open': $sidebar.showSidebar}" :style="[baseStyles]">
    <notifications></notifications>
    <router-view />
  </div>
</template>

<script>
export default {
  methods: {
  },
  computed: {
    baseStyles () {
      return {
        '--applicationTop': '64px',
        '--applicationLeft': '256px',
        '--scrollWidth': this.$isMobile ? '0px' : '18px'
      }
    }
  },
  created() {
    console.log(process.env.VUE_APP_MODE)
    console.log(process.env.VUE_APP_VERSION)
  }
};
</script>

<style lang="scss">
#main_top_bar {
  z-index: 999;
  position: relative;
}
.vue-notifyjs.notifications {
  .alert {
    z-index: 10000;
  }
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
.highlight {
  background-color: yellow;
}
.navbar .navbar-nav .nav-item .nav-link {
  margin: 5px 0px !important;
  padding: 5px 5px !important;
}
.card .card-body {
  padding: 5px;
}
.card .card-logo {
  padding: 5px;
  width: 127px;
  height: 110px;
  margin: 4px;
}
.wrapper .sidebar[data-active-color="info"] .nav .nav-item.active > .nav-link {
  color: #009b3a !important;
}
.tree-checkbox {
  width: 20px !important;
  height: 20px !important;
}
.tree-checkbox.checked:after {
  left: 5px !important;
  top: 0px !important;
  height: 13px !important;
  width: 7px !important;
}
.tree-anchor {
  font-size: 12px !important;
}
</style>
