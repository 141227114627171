import PlanoService from '@/services/plano.service'

export default {
  namespaced: true,
  state: {
    plano: null,
    planos: [],
  },
  mutations: {
    setPlanos(state, planos) {
      state.planos = planos
    },
    setPlano(state, plano) {
      state.plano = plano
    }
  },
  actions: {
    buscaPlano(
      { commit },
      {
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        entidade,
        profissao,
        administradora,
        faixas,
        detalhes,
        mei
      }
    ) {
      return PlanoService.busca(
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        entidade,
        profissao,
        administradora,
        faixas,
        detalhes,
        mei
      ).then(resp => {
        commit('setPlanos', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    }
  },
  getters: {
      getPlano(state) {
        return state.plano
      },
      getPlanos(state) {
          return state.planos
      }
  }
}
