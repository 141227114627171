<template>
    <Transition>
        <div v-if="showDivVideo" id="div-video-chamada">
            <div>
                <div id="div-video-chamada-botoes">
                    <a href="#" @click="fecharDivVideo" id="div-video-chamada-botoes-fechar" >
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </a>
                    <a href="https://app.simpsimuladoresonline.com.br/" target="_blank" style="width: 100%;height: 79%;" ></a>
                    <div id="div-nao-exibir-novamente">
                        <input type="checkbox" @click="setarCookie" v-model="naoPerguntarNovamente" ref="chkNaoPerguntar" />
                        <span>N&atilde;o exibir novamente</span>
                    </div>
                </div>
                <video autoplay loop muted playsinline id="video-da-chamada">
                    <source src="https://dashboard-simp.agencialink.com.br/resources/videos/video-simp-app.mp4" type="video/mp4">
                </video>
                <div v-if="showModalYoutube" id="modal-do-video-youtube">
                    <Transition>
                        <div>
                            <a href="https://app.simpsimuladoresonline.com.br/" target="_blank" id="div-video-chamada-botoes-fechar2" >
                                <font-awesome-icon :icon="['fas', 'times']" />
                            </a>
                            <!--iframe id="video-iframe-youtube" src="https://www.youtube.com/embed/vrUYc_kEKkc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe-->
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    </Transition>
</template>
<script>
import VueCookies from 'vue-cookies'
export default {
    data() {
        return {
            showDivVideo:false,
            showModalYoutube: false,
            naoPerguntarNovamente: false
        }
    },
    methods: {
        fecharDivVideo() {
            this.showDivVideo = false;
        },
        toggleModalYoutube() {
            this.showModalYoutube = this.showModalYoutube ? false : true;
        },
        setarCookie() {
            if(this.$refs.chkNaoPerguntar.checked) {
                VueCookies.set("div_video_novo_app_nao_exibir", 1, '180d' );
            } else {
                VueCookies.remove("div_video_novo_app_nao_exibir");
            }
        },
        lerCookie() {
            this.showDivVideo = VueCookies.get('div_video_novo_app_nao_exibir') != 1;
        }
    },
    created: function(){
        this.lerCookie()
    }
}
</script>
<style scope lang="scss">
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
    opacity: 0;
    }
    #div-video-chamada {
        z-index: 9;
        position: fixed; 
        width: 150px; 
        height: 230px; 
        bottom: 30px; 
        right: 30px;
        box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
        border-radius: 5px;
    }
    #div-video-chamada-botoes {
        position: absolute;
        display: flex;
        align-content: space-between;
        flex-wrap: wrap;
        height: 230px;
        margin: 4px;
        position: absolute;
        z-index: 1;
    }
    #div-video-chamada-botoes-fechar {
        display: flex;
        text-decoration: none;
        width: 140px;
        color: white;
        justify-content: flex-end;
    }
    #div-video-chamada-botoes-fechar2 {
        display: flex;
        text-decoration: none;
        color: white;
        justify-content: flex-end;
        margin: 10px;
        height: 20px;
        z-index: 9;
    }
    #div-nao-exibir-novamente {
        font-family: "Arial";
        color: white;
        font-size: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        margin: 5px auto;
    }
    #video-da-chamada {
        position: absolute;
        border-radius: 5px;
        background: url("~@/assets/videos/tabelas.jpg");
        background-repeat: no-repeat;
        width: 150px;
        height: 230px;
    }
    #modal-do-video-youtube {
        max-width: 308px;
        max-height: 550px;
        bottom: 30px;
        right: 0;
        margin: auto;
        position: absolute;
        z-index: 999;
        width: 90vw;
        height: 90vh;
        background-color: black;
        box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
    }
    #video-iframe-youtube{
        width: 90vw; 
        height: 90vh; 
        position: absolute; 
        max-width: 308px; 
        max-height: 550px;
    }
    #imagem-preview {
        display: none;
    }
    @media (max-width: 400px) {
        #modal-do-video-youtube{
            right: auto;
            left: 5vw;
            max-width: 100%;
            max-height: 100%;
        }
        #video-iframe-youtube{
            width: 90vw; 
            height: 90vh; 
            position: absolute; 
            max-width: 90vw; 
            max-height: 90vh;
        }

    }
    @media (max-width: 700px) {
        #div-video-chamada-botoes-fechar, #div-video-chamada-botoes-fechar2 {
            font-size: 2rem;
        }
    }
</style>