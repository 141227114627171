import LocalService from '@/services/local.service'

export default {
  namespaced: true,
  state: {
    data: null,
  },
  mutations: {
    setData: (state, data) => state.data = data
  },
  actions: {
    async ['save']({ commit }, data) {
      return LocalService.save(data).then(resp => {
        commit('setData', data)
        return Promise.resolve(resp)
      }, error => {
        return Promise.reject(error)
      })
    }
  },
  getters: {
    getData: (state) => state.data
  }
}