import RedeService from '@/services/rede.service'

export default {
  namespaced: true,
  state: {
    rede: null,
    redes: [],
  },
  mutations: {
    setRedes(state, redes) {
      state.redes = redes
    },
    setRede(state, rede) {
      state.rede = rede
    }
  },
  actions: {
    buscaRede(
      { commit },
      {
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        regiao,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        planos
      }
    ) {
      return RedeService.busca(
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        regiao,
        compulsorio,
        coparticipacao,
        acomodacao,
        credenciado,
        planos
      ).then(resp => {
        commit('setRedes', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    }
  },
  getters: {
      getRede(state) {
        return state.rede
      },
      getRedes(state) {
          return state.redes
      }
  }
}
