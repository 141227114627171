<template>
  <b-navbar toggleable="lg" type="dark" variant="success">
    <b-navbar-brand href="#" class="logo">
      <div class="logo-img">
        <img src="@/assets/img/logo-white.png" alt="logo" />
      </div>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <sidebar-img to="/" :name="user.name" :img="user.logotipo_usuario" />
        <sidebar-link to="/dashboard" name="Dashboard" icon="ti-panel" />
        <sidebar-link to="/cotacoes" name="Cotações Enviadas" icon="ti-panel" />
        <sidebar-link
          :to="{ name: 'tabelas', query: queryTabelas }"
          name="Tabelas"
          icon="ti-view-list-alt"
        />
        <sidebar-link to="/logout" name="Sair" icon="ti-power-off" />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
export default {
  data() {
    return {
      user: null,
      activeNotifications: false,
      filtros: {
        tipo_plano: null,
        contratacao: null,
        coparticipacao: null,
        compulsorio: null,
        acomodacao: null,
        profissao: null,
        cidade: null,
        credenciado: null
      }
    };
  },
  computed: {
    routeName() {
      const { meta } = this.$route;
      return this.capitalizeFirstLetter(meta.title);
    },
    queryTabelas() {
      const json = localStorage.getItem("filtros");
      const filtros = JSON.parse(json);

      this.filtros.tipo_plano = this.$route.query.tipo_plano
        ? this.$route.query.tipo_plano
        : filtros?.tipo_plano || "s";

      this.filtros.contratacao = this.$route.query.contratacao
        ? this.$route.query.contratacao
        : filtros?.contratacao || "pf";

      let queryCoparticipacao = this.$route.query.coparticipacao;
      this.filtros.coparticipacao = this.$route.query.hasOwnProperty(
        "coparticipacao"
      )
        ? queryCoparticipacao == ""
          ? 2
          : queryCoparticipacao == "s"
          ? 1
          : 0
        : Number.isInteger(filtros?.coparticipacao)
        ? filtros.coparticipacao
        : 2;

      let queryCompulsorio = this.$route.query.compulsorio;
      this.filtros.compulsorio = this.$route.query.hasOwnProperty("compulsorio")
        ? queryCompulsorio == ""
          ? 2
          : queryCompulsorio == "s"
          ? 1
          : 0
        : Number.isInteger(filtros?.compulsorio)
        ? filtros.compulsorio
        : 2;

      let queryAcomodacao = this.$route.query.acomodacao;
      this.filtros.acomodacao = this.$route.query.hasOwnProperty("acomodacao")
        ? queryAcomodacao == ""
          ? 2
          : queryAcomodacao == "s"
          ? 1
          : 0
        : Number.isInteger(filtros?.acomodacao)
        ? filtros.acomodacao
        : 2;

      this.filtros.cidade = filtros?.cidade?.id || "5213"; //{ "id":"5213","uf":"SP","cidade":"S\u00e3o Paulo" }
      this.filtros.credenciado = filtros?.credenciado;

      return this.filtros;
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    suporte() {},
    logout() {
      localStorage.removeItem("user");
      this.$router.push({ name: "login" });
    }
  },
  created() {
    const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID);
    const json = JSON.parse(id);
    const user = json?.user;
    this.user = user;
    console.log("User", this.user);
    window.b2corUserToken = user.id;
  }
  ,mounted() {
    let websocketScript = document.createElement('script')
    websocketScript.setAttribute('src', 'https://websocket.agencialink.com.br/websocket.js?_='+process.env.VUE_APP_VERSION)
    document.head.appendChild(websocketScript)
  }
};
</script>
<style lang="scss" scope>
.navbar {
  min-height: auto !important;
  padding: 0 20px !important;
  &.navbar-light {
    background-color: #019b3a !important;
    box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%) !important;
    border-bottom: 1px solid #019b3a !important;
  }
  & .navbar-brand.logo {
    padding: 0 !important;
    margin: 0 !important;
    & .logo-img {
      width: 145px;
      margin: 8px 10px 4px;
    }
  }
  & .navbar-toggler {
    margin: 0 !important;
  }
  & .container-fluid {
    margin: 0;
    padding: 10px;
  }
  & ul.navbar-nav {
    float: none;
    & li {
      color: #fff;
      margin-right: 12px;
    }
  }
}
</style>
