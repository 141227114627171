<template>
  <GoogleMapLoader
    :mapConfig="mapConfig"
    apiKey="AIzaSyCwYQW7Gyj4gRVbsUSgx8IsDzNLS5kt7qk">
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
      />
      <GoogleMapLine
        v-for="line in lines"
        :key="line.id"
        :path.sync="line.path"
        :google="google"
        :map="map"
      />
    </template>
  </GoogleMapLoader>
</template>

<script>
import GoogleMapLoader from "./Google/Maps/GoogleMapLoader";
import GoogleMapMarker from "./Google/Maps/GoogleMapMarker";
import GoogleMapLine from "./Google/Maps/GoogleMapLine";

import { mapSettings } from "@/constants/mapSettings";

export default {
  props: {
    id: Number,
    lat: Number,
    lng: Number
  },
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapLine
  },

  data() {
    return {
      markers: [
        {
          id: this.id,
          position: { lat: this.lat, lng: this.lng },
        }
      ],
      lines: [
        /*{
          id: "1",
          path: [{ lat: 3, lng: 101 }, { lat: 5, lng: 99 }]
        }*/
      ]
    };
  },
  computed: {
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter
      };
    },

    mapCenter() {
      return this.markers[0].position;
    }
  },
  created() {
    console.log(this.lat)
    console.log(this.lng)
  }
};
</script>
