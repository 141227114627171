import Vue from 'vue'

class ArquivoService {
  // https://api.simp.agencialink.com/arquivos/:uf/:produto/:tipo_plano/:contratacao/:cidade?compulsorio=:param&coparticipacao=:param
  busca(
    uf,
    produto,
    tipo_plano,
    contratacao,
    cidade,
    compulsorio,
    coparticipacao,
    administradora,
    entidade
  ) {
    let params = [
      { key: 'compulsorio', value: compulsorio },
      { key: 'coparticipacao', value: coparticipacao },
      { key: 'administradora', value: administradora },
      { key: 'entidade', value: entidade },
    ]
      .map(p => p.key+'='+(p.value||''))
      .join('&');
    return Vue.prototype.$http.get(
      `arquivos/${uf}/${produto}/${tipo_plano}/${contratacao}/${cidade||''}?${params}`
    )
    .then(response => {
      return response.data;
    });
  }
  buscaPreferenciais(id_corretora) {
    return Vue.prototype.$http.get(`arquivos/preferenciais/${id_corretora||''}`)
    .then(response => {
      return response.data;
    });
  }
}

export default new ArquivoService();
