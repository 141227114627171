<template>
  <div class="share-this">
    <div class="sharethis-inline-share-buttons" v-bind:data-url="url" data-title="Link da sua cotação de plano de saúde"></div>
  </div>
</template>

<script>
export default {
  props: ['url'],
  mounted() {
    function setup() {
        var c = window.__sharethis__.config['inline-share-buttons'].networks; //para remover o whatsapp
        window.__sharethis__.config['inline-share-buttons'].networks = c.filter(function(e) { return e !== 'whatsapp';});
        window.__sharethis__.initialize(window.__sharethis__.config);

        window.__sharethis__.open = function (url) {
            var h, w, wh, ww;
            if (!url) {
                return;
            }
            if (url.indexOf('mailto:') > -1 || url.indexOf('viber') > -1) {
                return document.location = url;
            } else {
                return window.open(url, '_blank');
            }
        };
    }
    const st = window.__sharethis__
    if (!st) {
      const script = document.createElement('script')
      script.src =
        'https://platform-api.sharethis.com/js/sharethis.js#property=642eb4f21cdb86001a1ecf2e>&product=inline-share-buttons'
      script.onload = function() {
        window.setTimeout(setup, 200);
      }
      document.body.appendChild(script)
    } else {
        window.setTimeout(setup, 200);
    }
  }
}
</script>

<style>
.share-this {
  margin-top: -1px;
  display: inline-block;
}

.share-this .st-btn {
  width: 58px !important;
  height: 58px !important;
}
.st-btn > img {
  width: 42px !important;
  height: 42px !important;
  top: 7px !important;
}

@media only screen and (max-width: 700px) {
  .share-this .st-btn {
    width: 39px !important;
    height: 39px !important;
    padding: 0 10px !important;
  }
  .st-btn > img {
    width: 28px!important;
    height: 28px!important;
    top: 6px!important;
  }
}
</style>