import DashboardLayout from "@/layout/dashboard/DashboardLayout"
import Login from "@/pages/Login"
import Logout from "@/pages/Logout"
import Tabelas from "@/pages/Tabelas"
import TabelaDetalhe from "@/pages/TabelaDetalhe"
import TabelaDetalheEdit from "@/pages/TabelaDetalheEdit"
import TabelaDetalhePrint from "@/pages/TabelaDetalhePrint"
import CotacaoLayout from "@/layout/CotacaoLayout"
import Cotacao from "@/pages/Cotacao"

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/tabelas",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        meta: { title: 'Dashboard' },
        beforeEnter() {
          const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
          const json = JSON.parse(id)
          const user = json?.user
          location.href = `https://barra.agencialink.com.br/autologin/${user.user_token}/simp_dashboard`
        }
      },
      {
        path: "cotacoes",
        name: "cotacoes",
        meta: { title: 'Cotações Enviadas' },
        beforeEnter() {
          const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
          const json = JSON.parse(id)
          const user = json?.user
          location.href = `https://barra.agencialink.com.br/autologin/${user.user_token}/simp_dashboard/?destino=cotacoes`
        }
      },
      {
        path: "tabelas",
        name: "tabelas",
        component: Tabelas,
        meta: { title: 'Tabelas', auth: true },
        props: true
      },
      {
        path: "tabela",
        name: "tabelaDetalhe",
        component: TabelaDetalhe,
        meta: { title: 'Tabela Detalhe', bodyClass: 'skin-blue-light sidebar-mini', auth: true },
        props: true
      },
      {
        path: "tabela/:hash",
        name: "tabelaDetalheEdit",
        component: TabelaDetalheEdit,
        meta: { title: 'Edição Tabela Detalhe', bodyClass: 'skin-blue-light sidebar-mini', auth: true },
        props: true
      }
    ]
  },
  {
    path: "/tabela/:id/print",
    name: "tabelaDetalhePrint",
    component: TabelaDetalhePrint,
    meta: { title: 'Impressão', auth: false },
    props: true
  },
  {
    name: 'cotacao',
    path: '/cotacao/:hash',
    meta: { bodyClass: 'skin-blue-light sidebar-mini pace-done', auth: false },
    component: CotacaoLayout,
    children: [
      {
        path: "/",
        name: "cotacaoHash",
        component: Cotacao,
        meta: { auth: false },
        props: true
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: "Login", bodyClass: "login-page", auth: false }
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: { title: "Logout", auth: false }
  },
  {
    path: "*",
    redirect: "/login"
  }
];

export default routes;
