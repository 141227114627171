<template>
  <div class="container">
    <div class="large-12 medium-12 small-12 filezone">
      <input type="file" id="files" ref="files" multiple v-on:change="handleFiles()"/>
      <p>
        Solte seus arquivos aqui <br> ou clique para pesquisar <br>
        Extensões permitidas: .gif, .jpeg, .jpg, .png, .doc, .docx, .pdf, .xls, .xlsx, .psd, .cdr, .ai e .txt<br>
        Tamanho máximo: 5MB
      </p>
    </div>
    <b-alert class="m-2" :variant="alertType" :show="alertShow" v-html="alertMsg"></b-alert>
    <div v-for="(file, key) in files" class="file-listing" :key="key">
      <img class="preview" v-bind:ref="'preview'+parseInt(key)" src="@/assets/img/generic.png" />
        {{ file.name }}
      <div class="success-container" v-if="file.id > 0">
        Arquivo enviado com sucesso
      </div>
      <div class="remove-container" v-else>
        <input v-model="file.client_name" class="form-control" />
        <a class="remove" v-on:click="removeFile(key)">Remover</a>
      </div>
    </div>

    <b-overlay
      :show="loading"
      rounded
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block"
    >
      <b-button @click="submitFiles" variant="primary" v-show="files.length > 0">
        Clique aqui para enviar
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      loading: false,
      user: null,
      files: [],
      uploads: [],
      alertType: '',
      alertShow: false,
      alertMsg: '',
    }
  },
  methods: {
    handleFiles() {
      let uploadedFiles = this.$refs.files.files;

      for(var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
      this.getImagePreviews();
    },
    getImagePreviews(){
      for( let i = 0; i < this.files.length; i++ ){
        if ( /\.(jpe?g|png|gif)$/i.test(this.files[i].name) ) {
          let reader = new FileReader();
          reader.addEventListener("load", function(){
            this.$refs['preview'+parseInt( i )][0].src = reader.result;
          }.bind(this), false);
          reader.readAsDataURL( this.files[i] );
        }
      }
    },
    removeFile( key ){
      this.files.splice( key, 1 );
      this.getImagePreviews();
    },
    async submitFiles() {
      this.loading = true
      const promises = this.files.map(async (file, index) => {
        if (!file.id) {
          let formData = new FormData()
          formData.append('file', file)
          formData.append('client_name', file.client_name)
          await Vue.prototype.$http.post(`arquivo`, formData, {
            headers: {
              'X-ID-CORRETORA': this.user.id,
              'Content-Type': 'multipart/form-data'
            }
          }).then((rest) => {
            console.log(rest)
            let upload = rest.data?.upload_data
            if (upload) {
              this.uploads[index] = {
                id: index,
                client_name: upload.client_name,
                file_ext: upload.file_ext,
                file_name: upload.file_name,
                file_path: upload.file_path,
                file_size: upload.file_size,
                file_type: upload.file_type,
                full_path: upload.full_path,
                image_height: upload.image_height,
                image_size_str: upload.image_size_str,
                image_type: upload.image_type,
                image_width: upload.image_width,
                is_image: upload.is_image,
                orig_name: upload.orig_name,
                raw_name: upload.raw_name
              };
              this.alertType = 'success'
              this.alertShow = true
              this.alertMsg = 'Arquivo(s) enviado(s) com sucesso!'
            } else {
              let error = rest.data?.error
              this.alertType = 'danger'
              this.alertShow = true
              this.alertMsg = error
            }
          }).catch(() => this.loading = false)
        }
      });
      await Promise.all(promises).then(() => {
        this.loading = false
        this.$emit('updateFiles', {
          files: this.files.map((file, index) => {
            return { file, ...this.uploads[index] }
          })
        })
        this.files = []
      }).catch((err) => {
        this.loading = false
        this.alertType = 'error'
        this.alertShow = true
        this.alertMsg = err
      })
    }
  },
  async mounted() {
    const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
    const json = JSON.parse(id)
    this.user = json?.user
  }
}
</script>

<style scoped>
input[type="file"]{
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.filezone {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: #ccc;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  position: relative;
  cursor: pointer;
}
.filezone:hover {
  background: #c0c0c0;
}

.filezone p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 50px 50px 50px;
}
div.file-listing img{
  max-width: 90%;
}

div.file-listing{
  margin: auto;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

div.file-listing img{
  height: 100px;
}
div.success-container{
  text-align: center;
  color: green;
}

div.remove-container{
  text-align: center;
}

div.remove-container a{
  color: red;
  cursor: pointer;
}

a.submit-button{
  display: block;
  margin: auto;
  text-align: center;
  width: 200px;
  padding: 10px;
  text-transform: uppercase;
  /* background-color: #CCC; */
  /* color: white; */
  font-weight: bold;
  margin-top: 20px;
  background-color: #41B883;
  border-color: #41B883;
  color: #FFFFFF;
  opacity: 1;
}
</style>
