<template>
  <div class="text-center h5 mt-4">
    <b-spinner
      variant="success"
      type="grow"
      v-if="!messsage"
    />
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: ['message']
}
</script>
<style>

</style>
