<template>
  <div>
    <b-modal id="modal-filter"
      @show="showModalFilter"
      @hidden="resetModalFilter"
      @ok="handleOkFilter"
      ok-only
      ok-variant="success"
      ok-title="Continuar">
      <template v-slot:modal-title>
        Personalizar o Modo de Exibição
      </template>
      <div class="row">
        <div class="col-sm-12 col-12">
          <b-alert :variant="alertType" :show="alertShow">
            {{ alertMsg }}
          </b-alert>
          <tree ref="tree" :data="treeData" :options="opts">
            <div slot-scope="{ node }" class="node-container">
              <div v-if="node.depth == 0">
                <div class="node-text">
                  <strong>{{ odonto(node.text) }}</strong>
                </div>
              </div>
              <div v-else>
                <div class="node-text">
                  {{ node.text }}
                </div>
              </div>
            </div>
          </tree>
          <p class="text-center" v-if="!treeData.length">Nenhum item encontrado</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    props: {
        tipoPlano: String,
        regioes: Array,
        reembolsos: Array,
        carencias: Array,
        procObrigatorios: Array,
        procDiferenciados: Array,
        docs: Array,
        infos: Array,
        arquivos: Array,
        callback: Function
    },
    data() {
        return {
            alertType: '',
            alertShow: false,
            alertMsg: '',
            opts: {
              checkbox: true,
            }
        }
    },
    methods: {
      checkFormValidity() {
        let tree = this.$refs.tree.toJSON()
        return tree.filter(
          t => t.state.checked == true||t.state.indeterminate == true
        ).length > 0
      },
      showModalFilter() {
        this.resetAlert()
      },
      resetModalFilter() {
      },
      handleOkFilter(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmitFilter()
      },
      resetAlert() {
        this.alertType = ''
        this.alertMsg = ''
        this.alertShow = false
      },
      handleSubmitFilter() {
          if (!this.checkFormValidity()) {
            this.alertType = 'warning'
            this.alertMsg = 'Selecione pelo menos um item'
            this.alertShow = true
            return
          }
          this.$store.dispatch('modalFilter/update', this.$refs.tree.toJSON())
          localStorage.setItem('filters', JSON.stringify(this.$refs.tree.toJSON()))
          // Hide the modal manually
          this.$nextTick(() => {
              this.$bvModal.hide('modal-filter')
              this.callback()
          })
      },
      odonto(text) {
        if (text == 'Valores' && this.tipoPlano == 'o') return 'Planos'
        return text
      }
    },
    computed: {
      ...mapGetters('modalFilter', ['getFilters']),
      treeData() {
        return this.getFilters || []
      }
    }
}
</script>

<style>
#modal-filter table tr th {
    position:unset;
}
#modal-filter .table thead tr > th,
#modal-filter .table thead tr > td,
#modal-filter .table tbody tr > th,
#modal-filter .table tbody tr > td,
#modal-filter .table tfoot tr > th,
#modal-filter .table tfoot tr > td {
    border: none;
}
#modal-filter .table-striped > thead > tr > th,
#modal-filter .table-striped > tbody > tr > th,
#modal-filter .table-striped > tfoot > tr > th,
#modal-filter .table-striped > thead > tr > td,
#modal-filter .table-striped > tbody > tr > td,
#modal-filter .table-striped > tfoot > tr > td {
    padding: 5px;
}
</style>
