export default [
  {
    "id": 64,
    "nome": "H IGESP",
    "nomeCompleto": "Hospital IGESP - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 95,
    "nome": "H Nove de Julho",
    "nomeCompleto": "Hospital Nove de Julho / 9 - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 105,
    "nome": "H Paulistano",
    "nomeCompleto": "Hospital Paulistano - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 119,
    "nome": "H Infantil Sabará",
    "nomeCompleto": "Hospital Infantil Sabará - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 122,
    "nome": "H Samaritano",
    "nomeCompleto": "Hospital Samaritano de São Paulo - Higienópolis - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 127,
    "nome": "H Sta Isabel",
    "nomeCompleto": "Hospital Santa Isabel  / Irmandade Santa Casa de Misericórdia - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 162,
    "nome": "H Sírio Libanês - Unid Bela Vista",
    "nomeCompleto": "Hospital Sírio Libanês - Unidade Bela Vista - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 311,
    "nome": "Lab Clube D A - SP",
    "nomeCompleto": "Clube D A Medicina Diagnóstica/DA Diagnostico da America - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 377,
    "nome": "Lab Delboni Auriemo",
    "nomeCompleto": "Delboni Auriemo Medicina Diagnostica",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 384,
    "nome": "Lab Fleming",
    "nomeCompleto": "Instituto de Analises Clinicas Fleming - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 400,
    "nome": "Lab Lavoisier - SP",
    "nomeCompleto": "Medicina Diagnostica Lavoisier",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 410,
    "nome": "Lab Bioclinico",
    "nomeCompleto": "Laboratorio Bioclinico",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 424,
    "nome": "Lab Salomao e Zoppi",
    "nomeCompleto": "Medicina Diagnostica Salomao e Zoppi",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 428,
    "nome": "Lab Schmillevitch",
    "nomeCompleto": "Clinica Schmillevitch",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 448,
    "nome": "Lab Ghelfond",
    "nomeCompleto": "Centro de Diagnostico Medico Dr Ghelfond",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 577,
    "nome": "Lab Diagnostika",
    "nomeCompleto": "Diagnostika",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 580,
    "nome": "H CECMI",
    "nomeCompleto": "Hospital CECMI / Saha Serviços Médicos e Hospitalares / Central Towers Hospital - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 583,
    "nome": "Lab Cimerman",
    "nomeCompleto": "Cimerman Analises Clinicas",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 588,
    "nome": "Lab Crya",
    "nomeCompleto": "CRYA  Clinica Radiologica Yeochua Avritchir",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 621,
    "nome": "H Adventista de São Paulo Unid Aclimação",
    "nomeCompleto": "Hospital Adventista de São Paulo Unidade Aclimação",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 972,
    "nome": "H Inglês - Oncologia",
    "nomeCompleto": "Hospital Inglês - Oncologia - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 1007,
    "nome": "Dayclinic Assistência Médica",
    "nomeCompleto": "Dayclinic Assistência Médica - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 8496,
    "nome": "Lab Dasa",
    "nomeCompleto": "Diagnosticos da America / Dasa",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 10611,
    "nome": "Lab UDO",
    "nomeCompleto": "UDO Unidade de Densitometria Óssea",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 10645,
    "nome": "Lab Militello",
    "nomeCompleto": "Militello Laboratório de Análises Clínicas",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 10822,
    "nome": "Amil Espaço Saúde - Unid Santana",
    "nomeCompleto": "Amil Espaço Saúde Santana / Clube Vida de Saúde - Unidade Santana / Hospital Vitória - Unidade Avançada Santana / Hospital Paulistano - Unidade Santana",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 13446,
    "nome": "H Leforte - Unid Liberdade",
    "nomeCompleto": "Hospital Leforte - Unidade Liberdade - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 13447,
    "nome": "H Alemão Oswaldo Cruz - Unid Liberdade/Vergueiro",
    "nomeCompleto": "Hospital Alemão Oswaldo Cruz - Unidade Vergueiro - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 13490,
    "nome": "Lab Senne Liquor",
    "nomeCompleto": "Senne Liquor Diagnóstico",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16321,
    "nome": "Cto Ortopédico Vergueiro",
    "nomeCompleto": "Centro Ortopédico Vergueiro - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16323,
    "nome": "Clín San Paolo - Santana",
    "nomeCompleto": "Clínica Médica San Paolo - São Paulo - SP",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16328,
    "nome": "S Pura e Simples - Santana",
    "nomeCompleto": "Saúde Pura e Simples - Sanatana - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16332,
    "nome": "Cons Méd Leão XIII - J São Bento",
    "nomeCompleto": "Consultorio Médico Leão XIII - Jardim São Bento - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16334,
    "nome": "Cedai Clín Esp Dermatologia - Santana",
    "nomeCompleto": "Cedai Clínica Especializada Dermatologia - Santana - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16344,
    "nome": "Medcenter Clín Méd - São Paulo",
    "nomeCompleto": "Medcenter Clínica Médica - República - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16345,
    "nome": "Ins Vascular Ricardo Gaspar",
    "nomeCompleto": "Instituto Vascular Ricardo Gaspar - Bela Vista - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16347,
    "nome": "Clín Dra Márcia Fabiana Seki - Unid ZN",
    "nomeCompleto": "Clínica Doutora Márcia Fabiana Seki - Unidade Zona Norte - Santa Cecília - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16349,
    "nome": "CEC - Clín de Especialidades Cirúrgicas",
    "nomeCompleto": "CEC - Clínica de Especialidades Cirúrgicas - Paraíso - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  },
  {
    "id": 16351,
    "nome": "Hemomed Inst Oncologia e Hematologia",
    "nomeCompleto": "Hemomed Instituto Oncologia e Hematologia - Pacaembu - São Paulo",
    "cidade": "São Paulo",
    "uf": "SP"
  }
]
