<template>
    <header class="main-header">
        <a class="sidebar-toggle"
            data-toggle="push-menu"
            role="button"
            v-if="source != 'iframe'"
            @click="collapse()">
            <font-awesome-icon :icon="['fas', 'bars']" />
            <span class="sr-only">Navegação</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </a>
        <a href="#" id="header-logo-text" class="logo hidden-xs">
        </a>
        <nav class="navbar navbar-static-top">
            <a href="#" class="logo">
                <span class="logo-lg"><b>Visualizar</b> Cotação</span>
                <span class="logo-mini"><b>SIMP</b></span>
            </a>
            <div class="navbar-custom-menu hidden-xs">
                <ul class="nav navbar-nav">
                    <li>
                        <a href="#" class="btn btn-warning btn-md" v-if="vencida">
                            <span>Status: Vencida</span>
                        </a>
                        <a href="#" class="btn btn-success btn-md" v-else-if="validade">
                            <span>Valido Até: {{ validade | data }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    props: ['validade', 'vencida'],
    data() {
        return {
            source: null
        }
    },
    methods: {
        collapse() {
            let header = window.document.body.querySelector('#header-logo-text')
            let style = window.getComputedStyle(header).display == 'block' ||
                Object.values(window.document.body.classList).indexOf('sidebar-collapse') != -1 ?
                    'sidebar-collapse' : 'sidebar-open'
            let has = Object.values(window.document.body.classList).indexOf(style) != -1
            if (!has) {
                window.document.body.classList.add(style)
            } else {
                window.document.body.classList.remove(style)
            }
        }
    },
    created() {
        this.source = this.$route.query.source
    }
}
</script>

<style lang="scss">
header {
    &.main-header {
        position: relative;
        max-height: 100px;
        z-index: 1030;
        & .sidebar-toggle {
            color: #fff;
            background-color: #0097e9;
            float: left;
            background-image: none;
            padding: 15px 15px 14px 15px;
        }
        & .logo {
            background-color: #38494f;
            color: #fff;
            border-bottom: 0 solid transparent;
            -webkit-transition: width .3s ease-in-out;
            -o-transition: width .3s ease-in-out;
            transition: width .3s ease-in-out;
            display: block;
            float: left;
            height: 50px;
            font-size: 20px;
            line-height: 50px;
            text-align: center;
            width: 230px;
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
            padding: 0 15px;
            font-weight: 300;
            overflow: hidden;
            & .logo-lg {
                b {
                    font-weight: 700;
                }
                display: block;
            }
            & .logo-mini {
                display: none;
            }
        }
        & .navbar {
            &.navbar-static-top {
                background-color: #38494f;
                -webkit-transition: margin-left .3s ease-in-out;
                -o-transition: margin-left .3s ease-in-out;
                transition: margin-left .3s ease-in-out;
                margin-bottom: 0;
                margin-left: 230px;
                border: none;
                min-height: 50px;
                border-radius: 0;
                
            }
        }
        & .navbar-custom-menu,
        & .navbar-right {
            float: right;
            & .btn-md {
                margin: 0;
            }
        }
    }
}
</style>