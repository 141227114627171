import Vue from 'vue'
import axios from 'axios'

class AuthService {
  constructor () {
    this.$http = axios.create({
      baseURL: process.env.VUE_APP_B2COR_API_URL
    })
  }
  async login(user) {
    try {
      const response = await this.$http.post('login', {
        login: user.login,
        senha: user.senha,
        permissao: 'simp_tabelas'
      })
      return await response.data
    } catch (error) {
      throw error
    }
  }
  async refresh(token) {
      try {
        const response = await this.$http.get(
          `login/token/${token}/simp_tabelas`
        )
        return await response.data
      } catch (error) {
        throw error
      }
  }
  logout() {
    localStorage.removeItem(process.env.VUE_APP_CLIENT_ID)
  }
  async saveSession(session) {
    try {
      const response = await Vue.prototype.$http.post(
        'save-session',
        session
      )
      return await response.data
    } catch (error) {
      throw error
    }
  }
  async readSession(session) {
    try {
      const response = await Vue.prototype.$http.post(
        'read-session',
        session
      )
      return await response.data
    } catch (error) {
      throw error
    }
  }
}

export default new AuthService();