<template>
    <aside class="main-sidebar">
        <section>
            <div class="row">
                <div class="col-md-12">
                    <div class="box box-primary">
                    <div class="logo_empresa box-body box-profile">
                        <div class="col-md-12">
                            <img :src="user.logotipo"
                                class="img-responsive"
                                :alt="user.empresa">
                        </div>
                    </div>
                    <div class="box-body box-profile">
                        <img class="profile-user-img img-responsive img-circle"
                            :src="user.logotipo_usuario"
                            alt=""
                             v-if="user.logotipo_usuario">
                        <h3 class="profile-username text-center">{{user.name}}</h3>
                        <p class="text-muted text-center">{{user.cargo}}</p>
                    </div>
                    <div class="box-body dados_corretor">
                        <ul class="list-group list-group-unbordered">
                            <li class="list-group-item break-all" v-if="user.email">
                                <font-awesome-icon :icon="['fas', 'envelope']" /> <a :href="`mailto:${user.email}`" class="">{{user.email}}</a>
                            </li>
                            <li class="list-group-item break-all" v-if="user.site">
                                <font-awesome-icon :icon="['fas', 'link']" /> <a :href="user.site" class="">{{user.site}}</a>
                            </li>
                            <li class="list-group-item" v-if="user.empresa">
                                <font-awesome-icon :icon="['fas', 'briefcase']" /> {{user.empresa}}
                            </li>
                            <li class="list-group-item  break-all" v-if="user.cartao">
                                <font-awesome-icon :icon="['fas', 'address-card']" style="color: purple" /> <a :href="user.cartao" class="">{{user.cartao}}</a>
                            </li>
                            <li class="list-group-item" v-for="phone in user.telefones" :key="phone.id">
                                <template v-if="phone.whatsapp == 0">
                                    <font-awesome-icon :icon="['fas', 'phone']" />
                                    <a :href="`tel:+55${phone.ddd}${phone.fone}`" class="ml-1 telefone" target="_blank">({{phone.ddd}}) {{phone.fone}}</a>
                                </template>
                                <template v-else>
                                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                                    <a :href="`https://wa.me/55${phone.ddd}${phone.fone}`" class="ml-1 telefone" target="_blank">({{phone.ddd}}) {{phone.fone}}</a>
                                </template>
                            </li>
                        </ul>
                    </div>
                    <div class="box-body">
                        <div class="row">
                            <div class="col-md-12">
                                <p class="text-center">
                                    <span>Clique no botão para me</span>
                                    <button type="button"
                                        class="btn btn-primary btn"
                                        @click="modalOpen('contato')">
                                        Enviar uma Mensagem
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="box-body" v-if="showRedesSociais">
                        <p class="text-center">Siga-nos nas Redes Sociais</p>
                        <p class="redes_sociais text-center">
                            <a target="_blank" :href="user.redes.facebook" class="mr-1" v-if="user.redes.facebook">
                                <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x" aria-hidden="true" />
                            </a>
                            <a target="_blank" :href="user.redes.twitter" class="mr-1" v-if="user.redes.twitter">
                                <font-awesome-icon :icon="['fab', 'twitter']" size="2x" aria-hidden="true" />
                            </a>
                            <a target="_blank" :href="user.redes.instagram" class="mr-1" v-if="user.redes.instagram">
                                <font-awesome-icon :icon="['fab', 'instagram']" size="2x" aria-hidden="true" />
                            </a>
                            <a target="_blank" :href="user.redes.google" class="mr-1" v-if="user.redes.google">
                                <font-awesome-icon :icon="['fab', 'google']" size="2x" aria-hidden="true" />
                            </a>
                            <a target="_blank" :href="user.redes.youtube" class="mr-1" v-if="user.redes.youtube">
                                <font-awesome-icon :icon="['fab', 'youtube']" size="2x" aria-hidden="true" />
                            </a>
                            <a target="_blank" :href="user.redes.linkedin" class="mr-1" v-if="user.redes.linkedin">
                                <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" aria-hidden="true" />
                            </a>
                        </p>
                    </div>
                    <div class="box-body" v-if="showAplicativos">
                        <p class="text-center">Baixe nosso aplicativo</p>
                        <p class="redes_sociais text-center">
                            <a target="_blank" :href="user.aplicativo" class="mr-1" v-if="user.aplicativo">
                                <font-awesome-icon :icon="['fab', 'apple']" size="2x" />
                            </a>
                            <a target="_blank" :href="user.aplicativo" class="ml-1" v-if="user.aplicativo">
                                <font-awesome-icon :icon="['fab', 'google-play']" size="2x" />
                            </a>
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </aside>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            default: function () {
                return {
                    id: '',
                    aplicativo: '',
                    barra_url: '',
                    cargo: '',
                    cartao: '',
                    email: '',
                    empresa: '',
                    login: '',
                    logotipo: '',
                    logotipo_usuario: '',
                    name: '',
                    redes: {
                        facebook: '',
                        google: '',
                        instagram: '',
                        linkedin: '',
                        twitter: '',
                        youtube: '',
                    },
                    site: '',
                    status: '',
                    telefones: [],
                    uf: ''
                }
            }
        }
    },
    methods: {
        modalOpen(modal) {
            if (modal == 'contato') {
                window.document.body.classList.remove('sidebar-open')
                this.$bvModal.show('modal-contato')
            }
        }
    },
    computed: {
      showRedesSociais() {
        if(this.user.redes === null){
            return;
        }
        return this.user.redes.hasOwnProperty('facebook') && this.user.redes.facebook.length > 0 ||
            this.user.redes.hasOwnProperty('google') && this.user.redes.google.length > 0 ||
            this.user.redes.hasOwnProperty('instagram') && this.user.redes.instagram.length > 0 ||
            this.user.redes.hasOwnProperty('linkedin') && this.user.redes.linkedin.length > 0 ||
            this.user.redes.hasOwnProperty('twitter') && this.user.redes.twitter.length > 0 ||
            this.user.redes.hasOwnProperty('youtube') && this.user.redes.youtube.length > 0
      },
      showAplicativos() {
        return this.user.hasOwnProperty('aplicativo') && this.user.aplicativo.length > 0
      }
    }
}
</script>

<style lang="scss">
    .main-sidebar {
        border-right: 1px solid #d2d6de;
        background-color: #f9fafc;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 50px;
        min-height: 100%;
        width: 230px;
        z-index: 810;
        -webkit-transition: -webkit-transform .3s ease-in-out,width .3s ease-in-out;
        -moz-transition: -moz-transform .3s ease-in-out,width .3s ease-in-out;
        -o-transition: -o-transform .3s ease-in-out,width .3s ease-in-out;
        transition: transform .3s ease-in-out,width .3s ease-in-out;
        & section {
            height: auto;
            padding-bottom: 10px;
            a {
                color: #444;
                text-decoration: none;
                &.telefone {
                    color: #5fa3c1;
                }
            }
            & .box {
                position: relative;
                border-radius: 3px;
                background: #ffffff;
                margin-bottom: 20px;
                width: 100%;
                box-shadow: 0 1px 1px rgba(0,0,0,0.1);
                &.box-primary {
                    border-top-color: #3c8dbc;
                }
                & .box-body {
                    border-top: 1px solid #dedede;
                    margin: 0 5px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 3px;
                    border-bottom-left-radius: 3px;
                    padding: 10px;
                    &:first-of-type {
                        border-top: none;
                    }
                    & img {
                        display: block;
                        max-width: 100%;
                        height: auto;
                        &.profile-user-img {
                            margin: 0 auto;
                            width: 100px;
                            padding: 3px;
                            border: 3px solid #d2d6de;
                            &.img-circle {
                                border-radius: 50%;
                            }
                        }
                    }
                    & .profile-username {
                        font-size: 19px;
                        margin-bottom: 1px;
                        margin-top: 5px;
                        font-weight: 500;
                        line-height: 1.1;
                        color: inherit;
                    }
                    p {
                        font-size: 14px;
                        &.text-muted {
                            color: #777;
                        }
                        &.text-center {
                            text-align: center;
                        }
                    }
                    & .list-group-item {
                        border: none;
                        padding: 0;
                    }
                    & .fa-google-play {
                        font-size: 1.6em;
                    }
                }
            }
        }
    }
</style>