import NotificarService from '@/services/notificar.service'

export default {
  namespaced: true,
  state: {
    status: null
  },
  mutations: {
    setStatus(state, status) {
      state.status = status
    },
  },
  actions: {
    enviar(
      { commit },
      { codigo, tipo }
    ) {
      return NotificarService.notificar(codigo, tipo).then(resp => {
        commit('setStatus', null)
        return Promise.resolve(resp)
      }, error => {
        return Promise.reject(error)
      })
    },
  },
  getters: {
    getStatus(state) {
        return state.status
    }
  }
}
