import Vue from 'vue'

class ReembolsoService {
  // https://api.simp.agencialink.com/reembolsos/:uf/:produto/:tipo_plano/:contratacao/:cidade?compulsorio=:param&coparticipacao=:param
  busca(
    uf,
    produto,
    tipo_plano,
    contratacao,
    cidade,
    compulsorio,
    coparticipacao,
    acomodacao,
    credenciado,
    planos,
    administradora,
    entidade
  ) {
    let params = [
      { key: 'compulsorio', value: compulsorio },
      { key: 'coparticipacao', value: coparticipacao },
      { key: 'acomodacao', value: acomodacao },
      { key: 'credenciado', value: credenciado },
      { key: 'administradora', value: administradora },
      { key: 'entidade', value: entidade },
    ]
      .map(p => p.key+'='+(p.value||''))
      .join('&');
    let paramsPlanos = planos ? params + '&' + planos.map(plano => 'planos[]='+plano).join('&') : params;
    return Vue.prototype.$http.get(
      `reembolsos/${uf}/${produto}/${tipo_plano}/${contratacao}/${cidade||''}?${paramsPlanos}`
    )
    .then(response => {
      return response.data;
    });
  }
}

export default new ReembolsoService();
