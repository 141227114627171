import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPaperPlane as fasPaperPlane,
  faEdit as fasEdit,
  faChevronDown as fasChevronDown,
  faChevronUp as fasChevronUp,
  faUser as fasUser,
  faUserCircle as fasUserCircle,
  faExternalLinkAlt as fasExternalLinkAlt,
  faBan as fasBan,
  faEnvelope as fasEnvelope,
  faLink as fasLink,
  faBriefcase as fasBriefcase,
  faAddressCard as fasAddressCard,
  faPhone as fasPhone,
  faQuestionCircle as fasQuestionCircle,
  faFolderOpen as fasFolderOpen,
  faPlus as fasPlus,
  faCalendarCheck as fasCalendarCheck,
  faHourglassEnd as fasHourglassEnd,
  faHandHoldingUsd as fasHandHoldingUsd,
  faUserTie as fasUserTie,
  faUserFriends as fasUserFriends,
  faGlobe as fasGlobe,
  faCogs as fasCogs,
  faComments as fasComments,
  faPowerOff as fasPowerOff,
  faGraduationCap as fasGraduationCap,
  faBars as fasBars,
  faTimes as fasTimes
} from '@fortawesome/free-solid-svg-icons'
import {
  faPaperPlane as farPaperPlane,
  faEdit as farEdit,
  faUser as farUser,
  faUserCircle as farUserCircle
} from '@fortawesome/free-regular-svg-icons'
import {
  faFacebook as fabFacebook,
  faFacebookSquare as fabFacebookSquare,
  faTwitter as fabTwitter,
  faInstagram as fabInstagram,
  faGoogle as fabGoogle,
  faYoutube as fabYoutube,
  faLinkedin as fabLinkedin,
  faApple as fabApple,
  faGooglePlay as fabGooglePlay,
  faWhatsapp as fabWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'


library.add(
  fasPaperPlane,
  farPaperPlane,
  fasEdit,
  farEdit,
  fasChevronUp,
  fasChevronDown,
  fasUser,
  fasUserCircle,
  farUser,
  farUserCircle,
  fasExternalLinkAlt,
  fasBan,
  fasEnvelope,
  fasLink,
  fasBriefcase,
  fasAddressCard,
  fasPhone,
  fasQuestionCircle,
  fasFolderOpen,
  fasPlus,
  fasCalendarCheck,
  fasHourglassEnd,
  fasHandHoldingUsd,
  fabFacebook,
  fabFacebookSquare,
  fabTwitter,
  fabInstagram,
  fabGoogle,
  fabYoutube,
  fabLinkedin,
  fabApple,
  fabGooglePlay,
  fabWhatsapp,
  fasUserFriends,
  fasGlobe,
  fasCogs,
  fasComments,
  fasPowerOff,
  fasGraduationCap,
  fasBars,
  fasTimes,
  fasUserTie
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)