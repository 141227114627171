import Vue from 'vue'

const isMobile = navigator.userAgent.match(/(Android|BlackBerry|iPad|iPhone|iPod|webOS|Windows Phone)/i)
const isIos = navigator.userAgent.match(/(iPad|iPhone|iPod)/i)
const isAndroid = navigator.userAgent.match(/(android)/i)

Vue.prototype.$isMobile = !!isMobile
Vue.prototype.$canShare = !!navigator.share

var classNames = [];

if (isIos) classNames.push('device-ios');
if (isAndroid) classNames.push('device-android');

var html = document.getElementsByTagName('html')[0];

if (classNames.length) classNames.push('on-device');
if (html.classList) html.classList.add.apply(html.classList, classNames);