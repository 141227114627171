import AuthService from '@/services/auth.service'

export default {
  namespaced: true,
  state: {
    usuario: null,
    usuarios: [],
    loading: false,
    session: null,
    error: null,
  },
  mutations: {
    setUsuario: (state, usuario) => state.usuario = usuario,
    setUsuarios: (state, usuarios) => state.usuarios = usuarios,
    setLoading: (state, loading) => state.loading = loading,
    setSession: (state, session) => state.session = session,
    setError: (state, error) => state.error = error
  },
  actions: {
    login({ commit }, usuario) {
      return AuthService.login(usuario).then(resp => {
        commit('setUsuario', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    },
    async ['refresh']({ commit }, token) {
      try {
        commit('setLoading', true)
        const data = await AuthService.refresh(token)
        commit('setUsuario', data)
      } catch (error) {
        commit('setError', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async ['saveSession']({ commit }, session) {
      try {
        commit('setLoading', true)
        const data = await AuthService.saveSession(session)
        commit('setSession', data)
      } catch (error) {
        commit('setError', error)
      } finally {
        commit('setLoading', false)
      }
    },
    async ['readSession']({ commit }, session) {
      try {
        commit('setLoading', true)
        const { data } = await AuthService.readSession(session)
        commit('setSession', data)
      } catch (error) {
        commit('setError', error)
      } finally {
        commit('setLoading', false)
      }
    },
  },
  getters: {
    getUsuario: state => state.usuario,
    getUsuarios: state => state.usuarios,
    getSession: state => state.session,
    getError: state => state.error
  }
}
