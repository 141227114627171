import ProfissaoService from '@/services/profissao.service'

export default {
  namespaced: true,
  state: {
    profissao: null,
    profissoes: []
  },
  mutations: {
    setProfissao(state, profissao) {
      state.profissao = profissao
    },
    setProfissoes(state, profissoes) {
      state.profissoes = profissoes
    }
  },
  actions: {
    selectedProfissao({ commit }, profissao) {
      commit('setProfissao', profissao)
    },
    buscaProfissao({ commit}, { uf, cidade, search }) {
      return ProfissaoService.busca(uf, cidade, search).then(resp => {
        commit('setProfissoes', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    }
  },
  getters: {
      getProfissao(state) {
        return state.profissao
      },
      getProfissoes(state) {
          return state.profissoes
      }
  }
}
