<template>
  <component :is="tag" class="nav-item" v-bind="$attrs" tag="li">
    <a class="nav-link">
      <slot>
        <img class="img_usuario_thumb" v-if="img" :src="img" />
        <span>{{ name }}</span>
      </slot>
    </a>
  </component>
</template>
<script>
export default {
  name: "sidebar-img",
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true
    },
    addLink: {
      default: () => {}
    },
    removeLink: {
      default: () => {}
    }
  },
  props: {
    name: String,
    img: String,
    tag: {
      type: String,
      default: "router-link"
    }
  },
  methods: {
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  }
};
</script>
<style scoped>
.img_usuario_thumb {
  max-width: 30px;
  max-height: 30px;
  border-radius: 50%;
  margin-top: -5px;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
