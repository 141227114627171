import RegiaoService from '@/services/regiao.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    regiao: null,
    regioes: [],
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setRegiao(state, regiao) {
      state.regiao = regiao
    },
    setRegioes(state, regioes) {
      state.regioes = regioes
    }
  },
  actions: {
    buscaRegiao(
      { commit },
      {
        uf,
        cidade,
        credenciado,
        redes
      }
    ) {
      commit('setLoading', true)
      return RegiaoService.busca(
        uf,
        cidade,
        credenciado,
        redes
      ).then(resp => {
        commit('setLoading', false)
        commit('setRegioes', resp)
        return Promise.resolve(resp);
      }, error => {
        commit('setLoading', false)
        return Promise.reject(error);
      })
    }
  },
  getters: {
    getLoading(state) {
      return state.loading
    },
    getRegiao(state) {
      return state.regiao
    },
    getRegioes(state) {
      return state.regioes
    }
  }
}
