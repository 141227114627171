<template>
  <div>
    <div class="wrapper">
      <div class="main-panel">
        <top-navbar v-if="app()"></top-navbar>
        <dashboard-content @click.native="toggleSidebar">
        </dashboard-content>
      </div>
    </div>
    <bottom-navbar v-if="logged()"></bottom-navbar>
    <banner-treinamento v-if="logged()"></banner-treinamento>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import DashboardContent from "./Content.vue"
import MobileMenu from "./MobileMenu"
import BottomNavbar from "./BottomNavbar"
import BannerTreinamento from "./BannerTreinamento"

export default {
  data() {
    return {
      filtros: {
        tipo_plano: null,
        contratacao: null,
        coparticipacao: null,
        compulsorio: null,
        acomodacao: null,
        profissao: null,
        cidade: null,
        credenciado: null
      },
      user: ''
    }
  },
  components: {
    TopNavbar,
    DashboardContent,
    MobileMenu,
    BottomNavbar,
    BannerTreinamento
  },
  computed: {
    queryTabelas() {
      const json = localStorage.getItem('filtros')
      const filtros = JSON.parse(json)

      this.filtros.tipo_plano = this.$route.query.tipo_plano ?
        this.$route.query.tipo_plano :
        filtros?.tipo_plano || 's'

      this.filtros.contratacao = this.$route.query.contratacao ?
        this.$route.query.contratacao :
        filtros?.contratacao || 'pf'

      let queryCoparticipacao = this.$route.query.coparticipacao
      this.filtros.coparticipacao = this.$route.query.hasOwnProperty('coparticipacao') ?
        (queryCoparticipacao == "" ? 2 : (queryCoparticipacao == 's' ? 1 : 0)) :
        (Number.isInteger(filtros?.coparticipacao) ? filtros.coparticipacao : 2)

      let queryCompulsorio = this.$route.query.compulsorio
      this.filtros.compulsorio = this.$route.query.hasOwnProperty('compulsorio') ?
        (queryCompulsorio == "" ? 2 : (queryCompulsorio == 's' ? 1 : 0)) :
        (Number.isInteger(filtros?.compulsorio) ? filtros.compulsorio : 2)

      let queryAcomodacao = this.$route.query.acomodacao
      this.filtros.acomodacao = this.$route.query.hasOwnProperty('acomodacao') ?
        (queryAcomodacao == "" ? 2 : (queryAcomodacao == 's' ? 1 : 0)) :
        (Number.isInteger(filtros?.acomodacao) ? filtros.acomodacao : 2)

      this.filtros.cidade = filtros?.cidade?.id || '5213'; //{ "id":"5213","uf":"SP","cidade":"S\u00e3o Paulo" }
      this.filtros.credenciado = filtros?.credenciado

      return this.filtros
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    logged() {
      return this.user
    },
    app() {
      return !this.user.hasOwnProperty('app')
    }
  },
  mounted() {
    const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
    const json = JSON.parse(id)
    this.user = json?.user
  }
};
</script>
