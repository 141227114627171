import Vue from 'vue'

class EntidadeService {
  // /entidade/:uf/:id_entidade
  busca(
    uf,
    entidadeId
  ) {
    return Vue.prototype.$http.get(
      `entidade/${uf}/${entidadeId}`
    )
    .then(response => {
      return response.data;
    });
  }
}

export default new EntidadeService();
