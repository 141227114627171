import InfoService from '@/services/info.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    infos: []
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setInfos(state, infos) {
      state.infos = infos
    }
  },
  actions: {
    buscaInfos(
      { commit },
      {
        uf,
        produto,
        tipo_plano,
        contratacao,
        planos,
        administradora,
        entidade
      }
    ) {
      commit('setLoading', true)
      return InfoService.busca(
        uf,
        produto,
        tipo_plano,
        contratacao,
        planos,
        administradora,
        entidade
      ).then(resp => {
        commit('setLoading', false)
        commit('setInfos', resp)
        return Promise.resolve(resp)
      }, error => {
        commit('setLoading', false)
        return Promise.reject(error)
      })
    }
  },
  getters: {
    getLoading(state) {
      return state.loading
    },
    getInfos(state) {
      return state.infos
    }
  }
}
