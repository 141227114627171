import ModalFilterService from '@/services/modal-filter.service'

export default {
  namespaced: true,
  state: {
    tipo: null,
    filters: [],
    loading: false,
    error: null
  },
  mutations: {
    setTipo: (state, tipo) => state.tipo = tipo,
    setFilters: (state, filters) => state.filters = filters,
    setLoading: (state, loading) => state.loading = loading,
    setError: (state, error) => state.error = error
  },
  actions: {
    tipo({commit}, tipo) {
      commit('setTipo', tipo)
    },
    async ['load']({ commit }, tree) {
      try {
        commit('setLoading', true)
        const data = await ModalFilterService.loadDefaultTree(tree)
        commit('setFilters', data)
      } catch (error) {
        commit('setError', errr)
      } finally {
        commit('setLoading', false)
      }
    },
    update({commit}, filters) {
      commit('setFilters', filters)
    }
  },
  getters: {
    getTipo: state => state.tipo,
    getFilters: state => state.filters
  }
}
