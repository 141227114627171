import Vue from 'vue'

class RegiaoService {
  // /regioes/:uf/:cidade?redes=:param
  busca(uf, cidade, credenciado, redes) {
    let params = redes.map(rede => 'redes[]='+rede).join('&');
    return Vue.prototype.$http.get(`regioes/${uf}?modelo=t&cidade=${cidade||''}&${params||''}&credenciado=${credenciado||''}`)
    .then(response => {
      return response.data;
    });
  }
}

export default new RegiaoService();
