<template>
  <b-modal id="modal-contato"
    class="note-toolbar card-header icheck-success d-inline"
    size="lg"

    @ok="handleOk">
    <template v-slot:modal-title>
      Preencha os seus dados e envie uma mensagem para nós!
    </template>
    <template v-slot:default>
      <b-form ref="form" @submit.stop.prevent="handleSubmit" autocomplete="off">
        <b-row class="mb-1">
          <b-col sm="12">
            <b-alert :variant="alertType" :show="alertShow">
              {{ alertMsg }}
            </b-alert>
          </b-col>
          <b-col sm="12" md="12">
            <b-form-group
              class="mb-2"
              small
              :state="nomeState"
              invalid-feedback="Este campo é requerido.">
              <b-form-input
                id="nome"
                class="m-0"
                :state="nomeState"
                v-model="nome"
                placeholder="Nome"
                required
                autocomplete="new-password"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col sm="12" md="12">
            <b-form-group
              class="mb-2"
              small
              :state="emailState"
              invalid-feedback="Este campo é requerido.">
              <b-form-input
                id="email"
                class="m-0"
                type="email"
                v-model="email"
                :state="emailState"
                placeholder="Email"
                required
                autocomplete="new-password"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col sm="12" md="6">
            <b-form-group
              class="mb-2"
              small
              :state="celularState">
              <b-form-input
                id="celular"
                class="m-0"
                type="tel"
                v-mask="'(##) #####-####'"
                v-model="celular"
                :state="celularState"
                placeholder="Whatsapp"
                autocomplete="new-password"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              class="mb-2"
              small
              :state="telefoneState"
              invalid-feedback="Este campo é requerido.">
              <b-form-input
                id="telefone"
                class="m-0"
                type="tel"
                v-mask="'(##) ####-####'"
                :masked="masked"
                v-model="telefone"
                :state="telefoneState"
                placeholder="Telefone"
                required
                autocomplete="new-password"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col sm="12" md="4">
            <b-form-group
              small
              label="Atendimento Via"
              label-for="atendimento">
              <b-form-select
                :options="atendimentos"
                v-model="atendimento"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="4">
            <b-form-group
              small
              label="Melhor dia"
              label-for="atendimento">
              <b-form-select
                v-model="dia"
                :options="dias"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="4">
            <b-form-group
              small
              label="Horário"
              label-for="horario">
              <b-form-input
                v-model="horario"
                placeholder="Ex: 10:30h da manhã, 14h da tarde etc."
                autocomplete="new-password"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col sm="12" md="12">
            <b-form-textarea
              id="mensagem"
              class="mb-0"
              placeholder="Digite aqui sua mensagem"
              rows="5"
              max-rows="6"
              minlength="5"
              v-model="mensagem"
              :state="mensagemState"
              invalid-feedback="Este campo é requerido."
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template v-slot:modal-footer="{ ok }">
      <b-button size="sm" variant="success" @click="ok()" :disabled="enviando">
        <b-spinner small variant="white" v-if="enviando" ></b-spinner>
        <font-awesome-icon :icon="['far', 'paper-plane']" v-else /> Enviar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {mask} from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['hash', 'cotacao', 'codigo', 'cliente_nome', 'cliente_email', 'cliente_telefone'],
  data() {
    return {
      enviando: false,
      alertType: '',
      alertShow: false,
      alertMsg: '',
      nome: '',
      nomeState: null,
      email: '',
      emailState: null,
      telefone: '',
      telefoneState: null,
      masked: false,
      celular: null,
      celularState: null,
      operadora: null,
      operadoras: [
        { value: null, text: 'Operadora' },
        { value: 'claro', text: 'Claro' },
        { value: 'conect', text: 'Conecta' },
        { value: 'nextel', text: 'Nextel' },
        { value: 'oi', text: 'Oi' },
        { value: 'tim', text: 'Tim' },
        { value: 'vivo', text: 'Vivo' },
        { value: 'outros', text: 'Outros' },
      ],
      atendimento: null,
      atendimentos: [
         { value: null, text: 'Selecione' },
         { value: 'Telefone', text: 'Telefone' },
         { value: 'E-mail', text: 'E-mail' },
         { value: 'Agendar Visita com Corretor', text: 'Agendar Visita com Corretor' },
      ],
      dia: null,
      dias: [
        { value: null, text: 'indiferente' },
        { value: 'Segunda', text: 'Segunda' },
        { value: 'Terca', text: 'Terca' },
        { value: 'Quarta', text: 'Quarta' },
        { value: 'Quinta', text: 'Quinta' },
        { value: 'Sexta', text: 'Sexta' },
        { value: 'Sabado', text: 'Sabado' },
        { value: 'Domingo', text: 'Domingo' },
      ],
      horario: null,
      mensagem: null,
      mensagemState: null
    }
  },
  directives: {mask},
  computed: {
    ...mapGetters('b2cor', ['getContato'])
  },
  methods: {
    ...mapActions('share', ['contato']),
    checkFormValidity() {
      const valid = this.$refs['form'].checkValidity()
      this.nomeState = this.$refs['form'].nome.checkValidity()
      this.emailState = this.$refs['form'].email.checkValidity()
      this.telefoneState = this.$refs['form'].telefone.checkValidity()
      this.celularState = this.$refs['form'].celular.checkValidity()
      this.mensagemState = this.$refs['form'].mensagem.checkValidity()
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }

      this.enviando = true
      await this.contato({
        codigo: this.hash,
        nome: this.nome,
        email: this.email,
        celular: this.celular,
        telefone: this.telefone,
        contato_tipo: this.atendimento,
        contato_data: this.dia,
        contato_horario: this.horario,
        mensagem: this.mensagem,
        informacao: `Referente à cotação ${this.cotacao}`
      })
      .then(() => {
        this.alertType = 'success'
        this.alertShow = true
        this.alertMsg = 'Mensagem enviada com sucesso!'
        this.enviando = false
      })
      .catch(() => {
        this.enviando = false
        this.alertType = 'danger'
        this.alertShow = true
        this.alertMsg = 'Não foi possivel enviar a mensagem!'
      })
    },
    bvModalShown() {
      this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
        if (modalId == 'modal-contato') {
          this.nome = this.cliente_nome
          this.email = this.cliente_email
          this.telefone = this.cliente_telefone
          this.masked = true
        }
      })
    },
  },
  mounted() {
    this.bvModalShown()
  }
}
</script>

<style>

</style>
