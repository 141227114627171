<template>
  <b-container fluid class="conya">
    <div class="side-left">
      <div class="sid-layy">
        <b-row class="slid-roo">
          <div class="data-portion"></div>
        </b-row>
      </div>
    </div>
    <div class="side-right">
      <img src="@/assets/img/logo-login.png" alt="logo" />
      <h2>Entre na sua conta</h2>
      <div class="alert alert-danger" v-if="alert">
        <button
          type="button"
          aria-hidden="true"
          class="close"
          @click="alert = false"
        >
          ×
        </button>
        <span>{{ alertMsg }}</span>
      </div>
      <b-form ref="login" @submit.stop.prevent="handleSubmitLogin">
        <b-form-group
          id="login"
          label="Login"
          label-for="login-input"
          label-align="left"
        >
          <b-form-input
            id="login-input"
            type="email"
            v-model.lazy.trim="form.login"
            required
            placeholder="Digite seu login"
            v-on:keyup.enter="$event.target.nextElementSibling.focus()"
          />
        </b-form-group>
        <b-form-group
          id="senha"
          label="Senha"
          label-for="senha-input"
          label-align="left"
        >
          <b-form-input
            id="senha-input"
            type="password"
            v-model="form.senha"
            required
            placeholder="Digite sua senha"
            v-on:keyup.enter="handleSubmitLogin"
          />
        </b-form-group>

        <b-row class="skjh mb-4">
          <b-col cols="6"> </b-col>
          <b-col cols="6">
            <a href="https://b2cor.agencialink.com.br/login/esqueceu.php">
              Esqueceu a senha?
            </a>
          </b-col>
        </b-row>

        <b-overlay
          :show="loading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="onHidden"
        >
          <b-button @click="handleBtnLogin" variant="primary">
            Acessar
          </b-button>
        </b-overlay>
      </b-form>
      <div id="imagem_do_dia" :class="{ open: open }">
        <a
          @click="creditos"
          class="button"
          title="Saiba mais sobre a imagem do dia"
          data-descricao-analytics="Login - info sobre a imagem do dia"
        >
          <i class="fa" :class="`fa-angle-${open ? 'up' : 'down'}`"></i>
        </a>
        <transition name="up">
          <div id="imagem_do_dia_descricao">
            <p>{{ copyright }}</p>
            <p>
              <a
                data-descricao-analytics="Login - Baixou imagem do dia"
                :href="`https://www.bing.com/hpwp/${hsh}`"
                target="_blank"
              >
                Clique aqui para baixar a Imagem do Dia
              </a>
            </p>
          </div>
        </transition>
      </div>
    </div>
    <b-modal
      id="esqueceu-senha"
      title="Esqueceu a Senha?"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="emailState"
          label="E-mail"
          label-for="email-input"
          invalid-feedback="E-mail é obrigatório"
        >
          <b-form-input
            id="email-input"
            v-model="email"
            :state="emailState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </b-container>
</template>

<script>
import "@/assets/css/style.css";

import { mapActions, mapGetters } from "vuex";

import Overlay from "@/components/Overlay";

export default {
  data() {
    return {
      form: {
        login: "",
        senha: "",
        conectado: false
      },
      loading: false,
      alert: false,
      alertMsg: "Usuário ou senha inválidos!",
      email: "",
      emailState: null,
      submittedEmails: [],
      open: false,
      copyright: "",
      hsh: ""
    };
  },
  components: {
    Overlay
  },
  computed: {
    ...mapGetters("usuario", {
      usuario: "getUsuario"
    })
  },
  methods: {
    ...mapActions("local", ["save"]),
    ...mapActions("usuario", ["login"]),
    ...mapActions("b2cor", ["pegarLead"]),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.emailState = valid;
      return valid;
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
    resetModal() {
      this.email = "";
      this.emailState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleBtnLogin(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmitLogin();
    },
    async handleSubmitLogin() {
      const valid = this.$refs.login.checkValidity();
      this.alert = false;
      if (!valid) {
        this.alert = true;
        if (!this.form.login) {
          this.alertMsg = "O usuário é obrigatório!";
        } else if (!this.form.senha) {
          this.alertMsg = "A senha é obrigatória!";
        }
        return;
      }
      await this.login(this.form);
      if (this.usuario.user_token) {
        await this.save({ key: "user", value: this.usuario });
        this.$router.push({
          name: "tabelas"
        });
      } else if (!this.usuario.status) {
        this.alert = true;
        this.alertMsg = this.usuario.msg;
      } else {
        this.alert = true;
        this.alertMsg = "Usuário ou senha inválidos.";
      }
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedEmails.push(this.email);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("esqueceu-senha");
      });
    },
    creditos() {
      this.open = !this.open;
    }
  },
  async mounted() {
    const token = this.$route.query.token,
      hash = this.$route.query.hasOwnProperty('hash') ? this.$route.query.hash : '',
      lead_id = this.$route.query.hasOwnProperty('lead_id') ? this.$route.query.lead_id : null,
      app = this.$route.query.hasOwnProperty("app") ? true : false;
    if (token) {
      await this.$store.dispatch("usuario/refresh", token);
      const user = this.$store.state.usuario.usuario;
      if (user) {
        if (app) user.app = true;
        this.save({ key: "user", value: user });
        if (hash) {
          window.location.href = `/tabela/${hash}`
        } else {
          this.$router.push({
            name: 'tabelas'
          })
        }
        
        if(lead_id != null) {
          const res = await this.pegarLead({ user: user.id, id: lead_id})
          .then((clientes) => {
            clientes = Object.values(clientes)
            
            if(clientes[0].celular_ddd == '' && clientes[0].fixo_ddd != '') {
              clientes[0].celular_ddd = clientes[0].fixo_ddd
              clientes[0].celular_numero = clientes[0].fixo_numero
              clientes[0].celular = clientes[0].telefone
            }
            
            localStorage.setItem('lead_associado', JSON.stringify(clientes[0]))
          })
        }
      }
    }
    
    await fetch(
      `${process.env.VUE_APP_CORS_URL}?https://www.bing.com/HPImageArchive.aspx?format=js&idx=0&n=1&mkt=pt-BR`
    )
      .then(res => res.json())
      .then(data => {
        if (data.images.length > 0) {
          let img = data.images[0],
            src = `https://www.bing.com/${img.url}`;
          document.body.style.backgroundImage = `url('${src}')`;
          document.body.style.backgroundSize = "cover";
          this.copyright = img.copyright;
          this.hsh = img.hsh;
        }
      })
      .catch(err => {
        console.log(err);
        let sid = document.querySelector(".side-left .sid-layy");
        if (sid) {
          sid.style.backgroundColor = "#00a8ffcc";
        }
      });
  }
};
</script>

<style scoped>
#imagem_do_dia {
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: right;
}
#imagem_do_dia.open #imagem_do_dia_descricao {
  /* display: block; */
  max-height: 300px;
  padding: 10px;
}
#imagem_do_dia a.button {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: rgba(51, 51, 51, 0.33);
  line-height: 30px;
  text-align: center;
}
#imagem_do_dia a {
  color: #000;
}
#imagem_do_dia_descricao {
  background: rgba(0, 0, 0, 0.24);
  margin-left: 30px;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
}
#imagem_do_dia_descricao p {
  font-size: 10px;
  text-align: justify;
  margin: 0;
}
</style>
