import CidadeService from '@/services/cidade.service'

export default {
  namespaced: true,
  state: {
    cidade: null,
    cidades: []
  },
  mutations: {
    setCidade(state, cidade) {
      state.cidade = cidade
    },
    setCidades(state, cidades) {
      state.cidades = cidades.map(cidade => cidade.nome)
    }
  },
  actions: {
    selectedCidade({ commit }, cidade) {
      commit('setCidade', cidade)
    },
    buscaCidade({ commit}, search) {
      return CidadeService.busca(search).then(resp => {
        commit('setCidades', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    }
  },
  getters: {
      getCidade(state) {
        return state.cidade
      },
      getCidades(state) {
          return state.cidades
      }
  }
}
