import Vue from 'vue'

class ProfissaoService {
  busca(uf, cidade, search) {
    return Vue.prototype.$http.get(
      `entidades_profissoes/${uf}/${cidade}?busca=${search}`
    )
    .then(response => {
      return response.data;
    })
  }
}

export default new ProfissaoService();
