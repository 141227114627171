import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './plugins/global'
import './plugins/bootstrap-vue'
import './plugins/fontawesome'
import './plugins/axios'
import './plugins/screen'

import PaperDashboard from "./plugins/paperDashboard";
import "vue-notifyjs/themes/default.css";

Vue.use(PaperDashboard);

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import { CoolSelectPlugin } from 'vue-cool-select'
import 'vue-cool-select/dist/themes/bootstrap.css'
Vue.use(CoolSelectPlugin)

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

import LiquorTree from 'liquor-tree'
Vue.use(LiquorTree)

import VueGtag from "vue-gtag"
Vue.use(VueGtag, {
  config: { id: "G-B518VT761Z" }
})

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)

import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css'
  ]
}

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false

Vue.filter('idade', idade => {
  let vidas = idade.split('-')
	return vidas[1] == '+' ? vidas.join(' ou ') : vidas.join(' a ')
})

Vue.filter('dinheiro', valor => {
	return `${parseFloat(valor).toFixed(2)}`.replace('.', ',')
})

Vue.filter('porcentagem', valor => {
	return `${parseFloat(valor).toFixed(2)}`.replace('.', ',') + ' % '
})

Vue.filter('slugify', text =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
)

Vue.filter('highlight', function(words, query) {
  var iQuery = new RegExp(query, "ig");
  return words ? !query ? words : words.toString().replace(iQuery, function(matchedTxt, a, b) {
      return ('<span class=\'highlight\'>' + matchedTxt + '</span>');
  }) : '';
})

Vue.filter('data', string => {
  if (!string) return
  const [ year, month, day] = string.split('-')
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
  return new Date(year, month-1, day)
    .toLocaleDateString("pt-br", options)
})

Vue.filter('dataLong', string => {
  if (!string) return
  const [date, time] = string.split(' ')
  const [year, month, day] = date.split('-')
  const [hour, minute] = time.split(':')
  const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
  return new Date(year, month-1, day, hour, minute)
    .toLocaleDateString("pt-br", options)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
