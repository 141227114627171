import ShareService from '@/services/share.service'

export default {
  namespaced: true,
  state: {
    form: null,
    cotacao: null,
    contato: null
  },
  mutations: {
    setForm: (state, form) => state.form = form,
    setCotacao: (state, cotacao) => state.cotacao = cotacao,
    setContato: (state, contato) => state.contato = contato
  },
  actions: {
    updateForm({commit}, form) {
      commit('setForm', form)
    },
    enviarCotacao(
      { commit },
      cotacao
    ) {
      return ShareService.enviarCotacao(cotacao).then(resp => {
        commit('setForm', null)
        commit('setCotacao', resp)
        return Promise.resolve(resp)
      }, error => {
        return Promise.reject(error)
      })
    },
    atualizarCotacao(
      { commit },
      cotacao
    ) {
      return ShareService.atualizarCotacao(cotacao).then(resp => {
        commit('setForm', null)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    },
    cotacao({ commit }, id) {
      return ShareService.cotacao(id).then(resp => {
        commit('setCotacao', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    },
    email({ commit }, cotacao) {
      return ShareService.email(cotacao).then(resp => {
        commit('setForm', null)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    },
    contato(
      { commit },
      {
        codigo,
        nome,
        email,
        celular,
        telefone,
        contato_tipo,
        contato_data,
        contato_horario,
        mensagem,
        informacao
      }
    ) {
      return ShareService.contato(
        codigo,
        nome,
        email,
        celular,
        telefone,
        contato_tipo,
        contato_data,
        contato_horario,
        mensagem,
        informacao
      ).then(resp => {
        commit('setContato', resp)
        return Promise.resolve(resp)
      }, error => {
        return Promise.reject(error)
      })
    }
  },
  getters: {
    getForm: (state) => state.form,
    getCotacao: (state) => state.cotacao,
    getContato: (state) => state.contato
  }
}
