import Vue from 'vue'

class NotificarService {
  // /notificacoes/notificar/:codigo/:tipo
  notificar(codigo, tipo) {
    return Vue.prototype.$http.get(`notificacoes/notificar/${codigo}/${tipo}`)
    .then(response => {
      return response.data;
    });
  }
}

export default new NotificarService();
