import CarenciaService from '@/services/carencia.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    carencias: []
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setCarencias(state, carencias) {
      state.carencias = carencias
    }
  },
  actions: {
    buscaCarencia(
      { commit },
      {
        uf,
        produto,
        tipo_plano,
        contratacao,
        administradora,
        entidade
      }
    ) {
      commit('setLoading', true)
      return CarenciaService.busca(
        uf,
        produto,
        tipo_plano,
        contratacao,
        administradora,
        entidade
      ).then(resp => {
        commit('setLoading', false)
        commit('setCarencias', resp)
        return Promise.resolve(resp)
      }, error => {
        commit('setLoading', false)
        return Promise.reject(error)
      })
    }
  },
  getters: {
    getLoading(state) {
      return state.loading
    },
    getCarencias(state) {
      return state.carencias
    }
  }
}
