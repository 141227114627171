import Vue from 'vue'

class CidadeService {
  busca(search) {
    return Vue.prototype.$http.get(`cidades?busca=${search}`)
      .then(response => {
        return response.data;
      });
  }
}

export default new CidadeService();
