import Vue from 'vue'

class EmailService {
  // /util/email_modelos
  modelos() {
    return Vue.prototype.$http.get(`util/email_modelos`)
    .then(response => {
      return response.data;
    });
  }
}

export default new EmailService();
