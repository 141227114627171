<template>
    <footer class="main-footer">
        <strong>Copyright © 2019 <a href="https://agencialink.com">AgenciaLink.com</a>.</strong>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss">
    footer {
        &.main-footer {
            border-top-color: #d2d6de;
            background: #fff;
            padding: 15px;
            color: #444;
            border-top: 1px solid #d2d6de;
            -webkit-transition: -webkit-transform .3s ease-in-out,margin .3s ease-in-out;
            -moz-transition: -moz-transform .3s ease-in-out,margin .3s ease-in-out;
            -o-transition: -o-transform .3s ease-in-out,margin .3s ease-in-out;
            transition: transform .3s ease-in-out,margin .3s ease-in-out;
            margin-left: 230px;
            z-index: 820;
            & strong {
                font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
                font-weight: 700;
                & a {
                    color: #3c8dbc;
                    text-decoration: none;
                }
            }
        }
    }
</style>