import ProcedimentoService from '@/services/procedimento.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    procedimento: null,
    procedimentos: [],
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setProcedimentos(state, procedimentos) {
      state.procedimentos = procedimentos
    },
    setProcedimento(state, procedimento) {
      state.procedimento = procedimento
    }
  },
  actions: {
    buscaProcedimentos(
      { commit },
      { uf, planos }
    ) {
      commit('setLoading', true)
      return ProcedimentoService.busca(
        uf, planos
      ).then(resp => {
        commit('setLoading', false)
        commit('setProcedimentos', resp)
        return Promise.resolve(resp)
      }, error => {
        commit('setLoading', false)
        return Promise.reject(error)
      })
    }
  },
  getters: {
    getLoading(state) {
      return state.loading
    },
    getProcedimento(state) {
      return state.procedimento
    },
    getProcedimentos(state) {
      return state.procedimentos
    }
  }
}
