import credenciados from '@/data/credenciados'

export default {
  namespaced: true,
  state: {
    credenciado: null,
    credenciados: []
  },
  mutations: {
    setCredenciados(state, credenciados) {
      state.credenciados = credenciados
    }
  },
  actions: {
    initCredenciados({commit}) {
      commit('setCredenciados', credenciados)
    }
  },
  getters: {
      getCredenciado(state) {
        return state.credenciado
      },
      getCredenciados(state) {
          return state.credenciados
      }
  }
}
