class ModalFilterService {
    recuperaValores(valores) {
        return valores.length > 0 ? valores.map(valor => {
            let valorSplit = valor.split('-')
            let data = valorSplit.length > 1
                ? { min: parseInt(valorSplit[0]), max: valorSplit[1] == '+' ? 99 : parseInt(valorSplit[1]), item: valor }
                : { item: valor }
            let text = valorSplit.length > 1
                ? valorSplit[1] == '+' ? valorSplit.join(' ou ') : valorSplit.join(' a ')
                : valorSplit[0]
            return {
                text: text, data: data, state: { checked: true }
            }
        }) : []
    }
    recuperaRegioes(regioes) {
        return regioes.length > 0 ? regioes.map((regiao) => {
            return {
                text: regiao.nome,
                data: { id: regiao.id, nome: regiao.nome, item: regiao.nome },
                state: { checked: true },
                children: regiao.cidades.map((cidade) => {
                    let hospitais = []
                    let laboratorios = []
                    let centros = []
                    if (cidade.credenciados.hasOwnProperty('Hospitais')) {
                        hospitais = cidade.credenciados['Hospitais'].map((c) => {
                            let nome = c.nome.trim()
                            return {
                                text: nome,
                                data: { id: c.id, nome: nome, item: c.id },
                                state: { checked: true },
                                children: []
                            }
                        })
                    }
                    if (cidade.credenciados.hasOwnProperty('Laboratórios')) {
                        laboratorios = cidade.credenciados['Laboratórios'].map((c) => {
                            let nome = c.nome.trim()
                            return {
                                text: nome,
                                data: { id: c.id, nome: nome, item: c.id },
                                state: { checked: true },
                                children: []
                            }
                        })
                    }
                    if (cidade.credenciados.hasOwnProperty('Centros Médicos')) {
                        centros = cidade.credenciados['Centros Médicos'].map((c) => {
                            let nome = c.nome.trim()
                            return {
                                text: nome,
                                data: { id: c.id, nome: nome, item: c.id },
                                state: { checked: true },
                                children: []
                            }
                        })
                    }
                    let childrens = [];
                    if (hospitais.length > 0) {
                        childrens.push({
                            text: 'Hospitais',
                            data: { item: 'Hospitais' },
                            state: { checked: true },
                            children: hospitais
                        })
                    }
                    if (laboratorios.length > 0) {
                        childrens.push({
                            text: 'Laboratórios',
                            data: { item: 'Laboratórios' },
                            state: { checked: true },
                            children: laboratorios
                        })
                    }
                    if (centros.length > 0) {
                        childrens.push({
                            text: 'Centros Médicos',
                            data: { item: 'Centros Médicos' },
                            state: { checked: true },
                            children: centros
                        })
                    }
                    return {
                        text: cidade.nome,
                        data: { id: cidade.id, nome: cidade.nome, item: cidade.nome },
                        state: { checked: true },
                        children: childrens
                    }
                })
            }
        }) : []
    }
    recuperaReembolsos(reembolsos) {
        return reembolsos.length > 0 ? reembolsos.reduce((acc, obj) => {
            if (!Array.isArray(acc)) acc = []
            obj.valores.forEach((r) => {
                let i = acc.findIndex(p => p.data.aId == r.aId)
                if (i == -1) {
                    acc.push({
                        text: r.nome,
                        data: { aId: r.aId, nome: r.nome, item: r.nome },
                        state: { checked: true }
                    })
                }
            })
            return acc
        }, []) : []
    }
    recuperaCarencias(carencias) {
        return carencias.length > 0 ? carencias.map((c) => {
            return {
                text: c.catNome,
                data: { catNome: c.catNome, catSecao: c.catSecao, item: c.catNome },
                state: { checked: true }
            }
        }) : []
    }
    recuperaProcObrigatorios(procObrigatorios) {
        return procObrigatorios.length > 0 ? procObrigatorios.map((proc) => {
            return {
                text: proc.nome,
                data: { cobId: proc.cobId, nome: proc.nome, item: proc.nome },
                state: { checked: true },
                children: proc.procs.map(p =>  {
                    return {
                        text: p.nome,
                        data: { proId: p.proId, nome: p.nome, item: p.nome },
                        state: { checked: true }
                    }
                })
            }
        }) : []
    }
    recuperaProcDiferenciados(procDiferenciados) {
        return procDiferenciados.length > 0 ? procDiferenciados.map((proc) => {
            return {
                text: proc.nome,
                data: { cobId: proc.cobId, nome: proc.nome, item: proc.nome },
                state: { checked: true },
                children: proc.procs.map(p =>  {
                    return {
                        text: p.nome,
                        data: { proId: p.proId, nome: p.nome, item: p.nome },
                        state: { checked: true }
                    }
                })
            }
        }) : []
    }
    recuperaDocs(docs) {
        return docs.length > 0 ? docs.map((d) => {
            return {
                text: d.catNome,
                data: { catNome: d.catNome, catSecao: d.catSecao, item: d.catNome },
                state: { checked: true }
            }
        }) : []
    }
    recuperaInfos(infos) {
        return infos.length > 0 ? infos.map((i) => {
            return {
                text: i.catNome,
                data: { catNome: i.catNome, catSecao: i.catSecao, item: i.catNome },
                state: { checked: true }
            }
        }) : []
    }
    recuperaArquivos(arquivos) {
        return arquivos.length > 0 ? arquivos.map((a) => {
            return {
                text: a.nome,
                data: { nome: a.nome, url: a.url, item: a.nome },
                state: { checked: true }
            }
        }) : []
    }
    getObjectTree(text, item, children) {
        return {
            text: text,
            data: { item: item },
            state: { checked: true },
            children: children
        }
    }
    recursiveLoadTree(filters, treeData) {
        return filters ? treeData.map((t) => {
            var filter = filters.find(f => f.text == t.text)
            if (filter) {
                t.state.checked = filter.state.checked == "1" ? true : false
                t.state.indeterminate = filter.state.indeterminate == "1" ? true : false
                if (t.hasOwnProperty('children')) {
                    if (t.children.length > 0) {
                        this.recursiveLoadTree(filter.children, t.children)
                    }
                }
            }
            return t
        }) : treeData
    }
    getData({
        tipoPlano,
        filters,
        valores,
        regioes,
        reembolsos,
        carencias,
        procObrigatorios,
        procDiferenciados,
        docs,
        infos,
        arquivos
      }) {
        let treeData = [
            this.getObjectTree('Valores', 'valor', this.recuperaValores(valores))
        ];
        if (tipoPlano != 'o') {
            treeData.push(
                this.getObjectTree('Redes', 'rede', this.recuperaRegioes(regioes)),
                this.getObjectTree('Reembolso', 'reembolso', this.recuperaReembolsos(reembolsos)),
                this.getObjectTree('Carência', 'carencia', this.recuperaCarencias(carencias)),
                this.getObjectTree('Docs', 'docs', this.recuperaDocs(docs)),
                this.getObjectTree('+Infos', 'infos', this.recuperaInfos(infos)),
                this.getObjectTree('Arquivos', 'arquivos', this.recuperaArquivos(arquivos))
            )
        } else if (tipoPlano == 'o') {
            treeData.push(
                this.getObjectTree('Carência', 'carencia', this.recuperaCarencias(carencias)),
                this.getObjectTree('Reembolso', 'reembolso', this.recuperaReembolsos(reembolsos)),
                this.getObjectTree('Proc. Obrigatórios', 'proc_obrigatorios', this.recuperaProcObrigatorios(procObrigatorios)),
                this.getObjectTree('Proc. Diferenciados', 'proc_diferenciados', this.recuperaProcDiferenciados(procDiferenciados)),
                this.getObjectTree('Docs', 'docs', this.recuperaDocs(docs)),
                this.getObjectTree('+Infos', 'infos', this.recuperaInfos(infos)),
                this.getObjectTree('Arquivos', 'arquivos', this.recuperaArquivos(arquivos))
            )
        }
        return this.recursiveLoadTree(filters, treeData)
    }
    async loadDefaultTree (data) {
        try {
            let self = this
            const promise = new Promise(
                function (resolve, reject) {
                    setTimeout(() => {
                        let tree = self.getData(data)
                        tree
                        ? resolve(tree)
                        : reject()
                    }, 100)
                }
            )
            return await promise
        } catch (error) {
            throw error
        }
    }
}

export default new ModalFilterService();