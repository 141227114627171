import B2CorService from '@/services/b2cor.service'

export default {
  namespaced: true,
  state: {
    indicacao: null,
    lead: [],
    leads: [],
    contato: null
  },
  mutations: {
    setIndicacao(state, indicacao) {
      state.indicacao = indicacao
    },
    setLead(state, lead) {
      state.lead = lead
    },
    setLeads(state, leads) {
      state.leads = leads
    },
    setContato(state, contato) {
      state.contato = contato
    }
  },
  actions: {
    buscaLeads(
      { commit },
      {
        user,
        search
      }
    ) {
      return B2CorService.buscaLeads(
        user,
        search
      ).then(resp => {
        commit('setLeads', resp)
        return Promise.resolve(resp)
      }, error => {
        return Promise.reject(error)
      })
    },
    pegarLead(
      { commit },
      {
        user,
        id
      }
    ) {
      return B2CorService.pegarLead(
        user,
        id
      ).then(resp => {
        return Promise.resolve(resp)
      }, error => {
        return Promise.reject(error)
      })
    },
    criarIndicacao(
      { commit },
      {
        nome,
        email,
        fone,
        assunto,
        cotacao
      }
    ) {
      return B2CorService.criarIndicacao(
        nome, email, fone, assunto, cotacao
      ).then(resp => {
        commit('setIndicacao', resp)
        return Promise.resolve(resp)
      }, error => {
        return Promise.reject(error)
      })
    },
    atualizarIndicacao(
      { commit },
      {
        indicacao,
        nome,
        email,
        fone,
        assunto,
        cotacao
      }
    ) {
      return B2CorService.atualizarIndicacao(
        indicacao, nome, email, fone, assunto, cotacao
      ).then(resp => {
        commit('setIndicacao', resp)
        return Promise.resolve(resp)
      }, error => {
        return Promise.reject(error)
      })
    },
    enviarMensagem(
      { commit },
      {
        id,
        contato_tipo,
        contato_data,
        contato_horario,
        mensagem,
        informacao
      }
    ) {
      return B2CorService.enviarMensagem(
        id, contato_tipo, contato_data, contato_horario, mensagem, informacao
      ).then(resp => {
        commit('setContato', resp)
        return Promise.resolve(resp)
      }, error => {
        return Promise.reject(error)
      })
    }
  },
  getters: {
    getIndicacao(state) {
      return state.indicacao
    },
    getLead(state) {
      return state.lead
    },
    getLeads(state) {
      return state.leads
    },
    getContato(state) {
      return state.contato
    }
  }
}
