<template>
    <div class="b-overlay-wrap position-relative" aria-busy="true">
        <slot></slot>
        <div class="b-overlay position-absolute"  v-if="show" style="inset: 0px; z-index: 10;">
            <div class="position-absolute bg-light rounded-sm"
                style="inset: 0px; opacity: 0.85; backdrop-filter: blur(2px);">
            </div>
            <div class="position-absolute" style="top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);">
                <loader
                :width="'200px'"
                :height="'200px'" />
            </div>
        </div>
    </div>
</template>

<script>
import Loader from './Loader'

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Loader
    },
}
</script>

<style>

</style>