import Vue from 'vue'

class CredenciadoService {
  busca(uf, cidade, search) {
    return Vue.prototype.$http.get(`credenciados/${uf||''}/${cidade||''}?busca=${search||''}`)
      .then(response => {
        return response.data;
      });
  }
}

export default new CredenciadoService();
