import Vue from 'vue'

class AdministradoraService {
  // /administradoras/:uf/:produto/:tipo_plano/:contratacao/:cidade?compulsorio=:param&coparticipacao=:param&credenciado=:param&entidade=:param&profissao=:param
  busca(
    uf,
    produto,
    tipo_plano,
    contratacao,
    cidade,
    compulsorio,
    coparticipacao,
    acomodacao,
    credenciado,
    entidade,
    profissao
  ) {
    let params = [
      { key: 'compulsorio', value: compulsorio },
      { key: 'coparticipacao', value: coparticipacao },
      { key: 'acomodacao', value: acomodacao },
      { key: 'credenciado', value: credenciado },
      { key: 'entidade', value: entidade },
      { key: 'profissao', value: profissao },
    ]
      .map(p => p.key+'='+(p.value||''))
      .join('&');
    return Vue.prototype.$http.get(
      `administradoras/${uf}/${produto}/${tipo_plano}/${contratacao}/${cidade||''}?${params}`
    )
    .then(response => {
      return response.data;
    });
  }
}

export default new AdministradoraService();
