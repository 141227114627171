import EmailService from '@/services/email.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    categoria: null,
    categorias: [],
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setCategoria(state, categoria) {
      state.categoria = categoria
    },
    setCategorias(state, categorias) {
      state.categorias = categorias
    }
  },
  actions: {
    buscaModelos(
      { commit }
    ) {
      commit('setLoading', true)
      return EmailService.modelos().then(resp => {
        commit('setLoading', false)
        commit('setCategorias', resp)
        return Promise.resolve(resp)
      }, error => {
        commit('setLoading', false)
        return Promise.reject(error)
      })
    },
  },
  getters: {
    getLoading(state) {
      return state.loading
    },
    getCategoria(state) {
      return state.categoria
    },
    getCategorias(state) {
      return state.categorias
    }
  }
}
