import ArquivoService from '@/services/arquivo.service'

export default {
  namespaced: true,
  state: {
    loading: false,
    arquivos: [],
    preferenciais: [],
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setArquivos(state, arquivos) {
      state.arquivos = arquivos
    },
    setPreferenciais(state, preferenciais) {
      state.preferenciais = preferenciais
    }
  },
  actions: {
    buscaArquivos(
      { commit },
      {
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        administradora,
        entidade
      }
    ) {
      commit('setLoading', true)
      return ArquivoService.busca(
        uf,
        produto,
        tipo_plano,
        contratacao,
        cidade,
        compulsorio,
        coparticipacao,
        administradora,
        entidade
      ).then(resp => {
        commit('setLoading', false)
        commit('setArquivos', resp)
        return Promise.resolve(resp)
      }, error => {
        commit('setLoading', false)
        return Promise.reject(error)
      })
    },
    buscaArquivosPreferenciais(
      { commit },
      {
        id_corretora
      }
    ) {
      return ArquivoService.buscaPreferenciais(
        id_corretora
      ).then(resp => {
        commit('setPreferenciais', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    }
  },
  getters: {
    getLoading(state) {
      return state.loading
    },
    getArquivos(state) {
      return state.arquivos
    },
    getArquivosPreferenciais(state) {
      return state.preferenciais
    }
  }
}
