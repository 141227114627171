import Vue from 'vue'

class CarenciaService {
  // https://api.simp.agencialink.com/carencias/sp/140/s/pf
  busca(
    uf,
    produto,
    tipo_plano,
    contratacao,
    administradora,
    entidade
  ) {
    let params = [
      { key: 'administradora', value: administradora },
      { key: 'entidade', value: entidade },
    ]
      .map(p => p.key+'='+(p.value||''))
      .join('&');
    return Vue.prototype.$http.get(`carencias/${uf}/${produto}/${tipo_plano}/${contratacao}?${params}`)
    .then(response => {
      return response.data;
    });
  }
}

export default new CarenciaService();
