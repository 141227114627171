<template>
  <div>Logout</div>
</template>

<script>
export default {
    created() {
       localStorage.removeItem(process.env.VUE_APP_CLIENT_ID)
       localStorage.removeItem('operadora')
       localStorage.removeItem('filters')
       localStorage.removeItem('filtros')
       localStorage.removeItem('cotacao')
       localStorage.removeItem('cotacao_hash')
       localStorage.removeItem('cotacao_id')
       this.$router.push({name: 'login'})
    }
}
</script>

<style>

</style>