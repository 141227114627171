<template>
  <div class="d-block d-lg-none d-print-none" >
    <nav class="nav_bottom" v-if="showBottom">
      <router-link to="/tabelas" class="nav__link" v-if="showBtnTabela">
        <span class="nav__icon ti-view-list-alt"></span>
        <span class="nav__text">Tabelas</span>
      </router-link>
      <a @click="modalOpen('personalizar')" class="nav__link" v-if="showBtnPersonalizar">
        <b-spinner
          small
          variant="success"
          v-if="!getLoadAllData()"
          title="Aguarde o carregamento dos dados"
        ></b-spinner>
        <span class="nav__icon ti-pencil-alt" v-if="getLoadAllData()"></span>
        <span class="nav__text">Personalizar</span>
      </a>
      <a @click="modalOpen('envio')" class="nav__link" v-if="showBtnEmail">
        <b-spinner
          small
          variant="success"
          v-if="!getLoadAllData()"
          title="Aguarde o carregamento dos dados"
        ></b-spinner>
        <span class="nav__icon ti-email" v-if="getLoadAllData()"></span>
        <span class="nav__text">Salvar/Enviar</span>
      </a>
      <a @click="modalOpen('impressao')" class="nav__link" v-if="showBtnPrint">
        <b-spinner
          small
          variant="success"
          v-if="!getLoadAllData()"
          title="Aguarde o carregamento dos dados"
        ></b-spinner>
        <span class="nav__icon ti-printer" v-if="getLoadAllData()"></span>
        <span class="nav__text">Imprimir</span>
      </a>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      route: null,
      filters: [],
      filtros: {
        tipo_plano: null,
        contratacao: null,
        coparticipacao: null,
        compulsorio: null,
        acomodacao: null,
        profissao: null,
        cidade: null,
        credenciado: null
      },
      tabs: [],
      tabsObj: [],
      operadora: {
        nome: '',
        logotipoavo: '',
        logotipo: '',
        cidade: { id: '', cidade: '', uf: '' },
        tipo_plano: 's',
        coparticipacao: 2,
        compulsorio: 2,
        acomodacao: 2,
      },
      produto: {
        id: '',
        nome: ''
      },
      planos: [],
      planosPorRede: [],
      grupos: [],
      tipoFilter: null,
      showBottom: false,
      showBtnTabela: false,
      showBtnPersonalizar: false,
      showBtnEmail: false,
      showBtnPrint: false
    }
  },
  methods: {
    ...mapActions('usuario', ['readSession']),
    ...mapActions('modalFilter', ['load']),
    async modalOpen(tipo) {
      if (this.getLoadAllData()) {
        if (tipo == 'personalizar') {
          await this.loadFilters()
          this.$bvModal.show('modal-filter')
        } else if (tipo == 'envio') {
          this.$bvModal.show('modal-share')
        } else if (tipo == 'impressao') {
          this.print()
        }
      }
    },
    async loadFilters() {
      this.filters = this.getFilters
    },
    getLoadAllData() {
      return !this.loadingValores &&
        !this.loadingRegiao &&
        !this.loadingReembolsos &&
        !this.loadingCarencias &&
        !this.loadingProcedimentos &&
        !this.loadingDocs &&
        !this.loadingInfos &&
        !this.loadingArquivos
    },
    async loadAllData() {
      const jsonOperadora = localStorage.getItem('operadora')
      const operadora = JSON.parse(jsonOperadora)

      await this.readSession({
        id: this.$route.query.hash
      })

      this.filtros = {
        tipo_plano: this.$route.query.tipo_plano,
        contratacao: this.$route.query.contratacao,
        coparticipacao: this.$route.query.coparticipacao,
        compulsorio: this.$route.query.compulsorio,
        acomodacao: this.$route.query.acomodacao,
        profissao: this.$route.query.profissao,
        cidade: this.$route.query.cidade,
        credenciado: this.$route.query.credenciado
      };

      this.tabsObj = this.getSession?.tabs ? this.getSession.tabs : this.getTabs
      this.operadora = this.getSession ? this.getSession : (this.getOperadora ? this.getOperadora : operadora)
      this.filters = this.getSession ? this.getSession.filters : (this.getFilters ? this.getFilters : operadora.filters)
      this.produto = this.getSession ? this.getSession.produto : (this.getProduto ? this.getProduto : operadora.produto)
      this.planos = this.getSession ? this.getSession.planos : (this.getPlanos.length > 0 ? this.getPlanos : operadora.planos)
      this.planosPorRede = this.getSession ? this.getSession.planosPorRede : (this.getPlanosPorRede.length > 0 ? this.getPlanosPorRede : operadora.planosPorRede)
      this.valores = this.getSession ? this.getSession.valores : (this.operadora.valores.length > 0 ? this.operadora.valores : operadora.valores)
      this.grupos = this.getSession ? this.getSession.grupos : (this.getGrupos.length > 0 ? this.getGrupos : operadora.grupos)

      this.operadora['grupos'] = this.grupos
    },
    async print() {
      await this.loadAllData()

      this.operadora.planos = this.planos
      this.operadora.planosPorRede = this.planosPorRede
      this.operadora.regioes = this.regioes
      this.operadora.reembolsos = this.reembolsos
      this.operadora.carencias = this.carencias
      this.operadora.docs = this.docs
      this.operadora.infos = this.infos
      this.operadora.arquivos = this.arquivos

      const json = localStorage.getItem('filtros')
      const filtros = JSON.parse(json)
      const cotacao_id = localStorage.getItem('cotacao_id')

      let routeData = this.$router.resolve({
        name: 'tabelaDetalhePrint',
        params: {
          id: cotacao_id ? 'cotacao' : this.operadora.id
        },
        query: {
          tipo_plano: filtros.tipo_plano,
          contratacao: filtros.contratacao,
          compulsorio: filtros.compulsorio == 2 ? '' : filtros.compulsorio == 1 ? 's' : 'n',
          coparticipacao: filtros.coparticipacao == 2 ? '' : filtros.coparticipacao == 1 ? 's' : 'n',
          acomodacao: filtros.acomodacao == 2 ? '' : filtros.acomodacao == 1 ? 'a' : 'e',
          profissao: filtros.profissao ? filtros.profissao : '',
          cidade: filtros.cidade ? filtros.cidade.id : '',
          credenciado: filtros.credenciado ? filtros.credenciado : '',
          hash: this.$route.query.hash
        }
      })
      console.log(routeData)
      window.open(routeData.href, '_blank');
    },
  },
  computed: {
    ...mapGetters('usuario', ['getSession']),
    ...mapGetters('modalFilter', ['getFilters']),
    ...mapGetters('operadora', {
      getOperadora: 'getOperadora',
      getPlanos: 'getPlanos',
      getPlanosPorRede: 'getPlanosPorRede',
      loadingValores: 'getLoadingGrupos',
      getGrupos: 'getGrupos'
    }),
    ...mapGetters('regiao', {
      loadingRegiao: 'getLoading',
      regioes: 'getRegioes'
    }),
    ...mapGetters('reembolso', {
      loadingReembolsos: 'getLoading',
      reembolsos: 'getReembolsos'
    }),
    ...mapGetters('carencia', {
      loadingCarencias: 'getLoading',
      carencias: 'getCarencias'
    }),
    ...mapGetters('procedimento', {
      loadingProcedimentos: 'getLoading',
    }),
    ...mapGetters('docs', {
      loadingDocs: 'getLoading',
      docs: 'getDocs'
    }),
    ...mapGetters('info', {
      loadingInfos: 'getLoading',
      infos: 'getInfos'
    }),
    ...mapGetters('arquivo', {
      loadingArquivos: 'getLoading',
      arquivos: 'getArquivos'
    })
  },
  watch: {
    $route(to, from) {
      if (to.name == 'tabelaDetalhe') {
        this.showBottom = true
        this.showBtnTabela =  true
        this.showBtnPersonalizar = true
        this.showBtnEmail = true
        this.showBtnPrint = true
      } else {
        this.showBottom = false
        this.showBtnTabela =  false
        this.showBtnPersonalizar = false
        this.showBtnEmail = false
        this.showBtnPrint = false
      }
    }
  },
  async mounted() {
    if (this.$router.currentRoute.name == 'tabelaDetalhe') {
      this.showBottom = true
      this.showBtnTabela =  true
      this.showBtnPersonalizar = true
      this.showBtnEmail = true
      this.showBtnPrint = true
    } else {
      this.showBottom = false
      this.showBtnTabela =  false
      this.showBtnPersonalizar = false
      this.showBtnEmail = false
      this.showBtnPrint = false
    }
  }
}
</script>

<style lang="scss" scope>
  .nav_bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 45px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #1e9b3ab8;
    display: flex;
    overflow-x: auto;
    z-index: 10;
    & .nav__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      min-width: 50px;
      overflow: hidden;
      white-space: nowrap;
      font-family: sans-serif;
      font-size: 13px;
      color: #fff;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
      transition: background-color 0.1s ease-in-out;
      &:hover {
        background-color: #177e3d;
      }
      &.active {
        color: #00551f;
      }
      .nav__icon {
        font-size: 15px;
      }
      .nav__text {
        font-size: 10px;
        margin-top: 2px;
      }
    }
  }
</style>
