import EntidadeService from '@/services/entidade.service'

export default {
  namespaced: true,
  state: {
    entidade: null,
    entidades: [],
  },
  mutations: {
    setEntidade(state, entidade) {
      state.entidade = entidade
    },
    setEntidades(state, entidades) {
      state.entidades = entidades
    }
  },
  actions: {
    buscaEntidades(
      { commit },
      {
        uf,
        entidadeId
      }
    ) {
      return EntidadeService.busca(
        uf,
        entidadeId
      ).then(resp => {
        commit('setEntidades', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    }
  },
  getters: {
      getEntidade(state) {
        return state.entidade
      },
      getEntidades(state) {
        return state.entidades
      }
  }
}
