import Vue from 'vue'

class RedeService {
  // https://api.simp.agencialink.com/redes/:uf/:produto/:tipo_plano/:contratacao/:cidade?regiao=:param&compulsorio=:param&coparticipacao=:param&acomodacao=:param&credenciado=:param&planos[]=:param
  busca(
    uf,
    produto,
    tipo_plano,
    contratacao,
    cidade,
    regiao,
    compulsorio,
    coparticipacao,
    acomodacao,
    credenciado,
    planos
  ) {
    let params = [
      { key: 'regiao', value: regiao },
      { key: 'compulsorio', value: compulsorio },
      { key: 'coparticipacao', value: coparticipacao },
      { key: 'acomodacao', value: acomodacao },
      { key: 'credenciado', value: credenciado },
    ]
      .map(p => p.key+'='+(p.value||''))
      .join('&');
    let paramsPlanos = planos ? params + '&' + planos.map(plano => 'planos[]='+plano).join('&') : params;
    console.log(paramsPlanos)
    return Vue.prototype.$http.get(
      `redes/${uf}/${produto}/${tipo_plano}/${contratacao}/${cidade||''}?${paramsPlanos}`
    )
    .then(response => {
      return response.data;
    });
  }
}

export default new RedeService();
