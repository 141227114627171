import Vue from 'vue'

class ProcedimentoService {
  busca(uf, planos) {
    let paramsPlanos = planos ? planos.map(plano => 'planos[]='+plano).join('&') : '';
    return Vue.prototype.$http.get(
      `procedimentos/${uf}?${paramsPlanos}`
    )
    .then(response => {
      return response.data;
    });
  }
}

export default new ProcedimentoService();
