<template>
  <div class="loader" :style="[baseStyles]"></div>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '100px'
        },
        height: {
            type: String,
            default: '100px'
        },
        color: {
            type: String,
            default: 'black'
        }
    },
    computed: {
        baseStyles() {
            let img = require(`../assets/img/loader-${this.color}.gif`)
            return {
                'width': this.width,
                'height': this.height,
                'background-image': 'url('+ img +')'
            }
        }
    }
}
</script>

<style scope lang="scss">
.loader {
    display: inline-block;
    background-size: cover;
    background-position: center;
}
</style>