import CredenciadoService from '@/services/credenciado.service'

export default {
  namespaced: true,
  state: {
    credenciado: null,
    credenciados: []
  },
  mutations: {
    setCredenciado(state, credenciado) {
      state.credenciado = credenciado
    },
    setCredenciados(state, credenciados) {
      state.credenciados = credenciados
    }
  },
  actions: {
    selectedCredenciado({ commit }, credenciado) {
      commit('setCredenciado', credenciado)
    },
    buscaCredenciado({ commit}, { uf, id, search }) {
      return CredenciadoService.busca(uf, id, search).then(resp => {
        commit('setCredenciados', resp)
        return Promise.resolve(resp);
      }, error => {
        return Promise.reject(error);
      })
    }
  },
  getters: {
      getCredenciado(state) {
        return state.credenciado
      },
      getCredenciados(state) {
        return state.credenciados
      }
  }
}
